import validatorBuilder from 'validator-builder';

let configurationEmailSmtpFormValidator = null;

export default class ConfigurationEmailSmtpForm {
	constructor(data) {
		data = data || {};

		this.id = data.id || null;
		this.idSociete = data.idSociete || null;
		this.smtpLogin = data.smtpLogin || null;
		this.smtpPass = data.smtpPass || null;
		this.smtpServer = data.smtpServer || null;
		this.smtpPort = data.smtpPort || 0;
		this.smtpAlias = data.smtpAlias || null;
		this.smtpIsSslConnection = data.smtpIsSslConnection || false;
	}

	isValid() {
		configurationEmailSmtpFormValidator = configurationEmailSmtpFormValidator || validatorBuilder.getInstanceFor('ConfigurationEmailSmtpForm');

		const validationResults = configurationEmailSmtpFormValidator.validate(this);

		return validationResults.isValid;
	}
}

/* @ngInject */
export default function PlanImpressionPrixController(
	$scope,
	$state,
	$stateParams,
	PlanImpressionPrixService,
	TemplateImpressionService,
	ModalService,
	$uibModal,
	notification,
	$location,
	$anchorScroll,
	$translate,
	$filter,
	_,
	moment,
	PaginationService,
	MOPService
) {
	const vm = this;

	let previousTableState;
	vm.lastSelected = undefined;

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.$scope = $scope;
	vm.$state = $state;
	vm.$stateParams = $stateParams;
	vm.PlanImpressionPrixService = PlanImpressionPrixService;
	vm.TemplateImpressionService = TemplateImpressionService;
	vm.ModalService = ModalService;
	vm.$uibModal = $uibModal;
	vm.notification = notification;
	vm.$location = $location;
	vm.$anchorScroll = $anchorScroll;
	vm.$translate = $translate;
	vm.$filter = $filter;
	vm._ = _;

	vm.ongletOpen = {
		isEnteteOpen: true,
		isClientSelectionOpen: true
	};

	vm.societes = [];
	vm.societesSelected = [];
	vm.sites = [];
	vm.sitesSelected;
	vm.clients = [];
	vm.clientsSelected = [];
	vm.chantiers = [];
	vm.selected = false;
	vm.isFilter = false;
	const dateNow = new Date();
	vm.annee = dateNow.getFullYear();
	vm.dateDebut = null;
	vm.dateFin = null;
	vm.moment = moment;
	vm.chantiersAvecPrix = false;
	vm.chantierSansPrix = true;

	vm.getSocietesCommerciales = getSocietesCommerciales;
	vm.getSitesCommerciaux = getSitesCommerciaux;
	vm.getClientSelections = getClientSelections;
	vm.getClients = getClients;
	vm.societeChange = societeChange;
	vm.clientsChange = clientsChange;
	vm.anneeChange = anneeChange;
	vm.checkAllClients = checkAllClients;
	vm.checkAllChantiers = checkAllChantiers;
	vm.print = print;
	vm.download = download;
	vm.annuler = annuler;
	vm.startLoading = startLoading;
	vm.stopLoading = stopLoading;
	vm.openHelper = openHelper;
	vm.exporter = exporter;
	vm.openModalFranco = openModalFranco;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([
			{ title: 'PLAN_IMPRESSION_PRIX.BREADCRUMBS.PLAN_IMPRESSION_PRIX', filename: 'PlanImpressionPrix.pdf', application: 'gestion' }
		]);
		getSocietesCommerciales();
		anneeChange();

		vm.unregister = [];
		vm.unregister.push(
			vm.$scope.$watch(
				() => vm.dateDebut,
				async () => {
					if (vm.dateDebut && (vm.chantiersAvecPrix || vm.chantierSansPrix)) {
						clientsChange();
					}
				}
			)
		);
		vm.unregister.push(
			vm.$scope.$watch(
				() => vm.dateFin,
				async () => {
					if (vm.dateFin && (vm.chantiersAvecPrix || vm.chantierSansPrix)) {
						clientsChange();
					}
				}
			)
		);
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	async function getSocietesCommerciales() {
		vm.startLoading();
		try {
			vm.societes = await vm.PlanImpressionPrixService.getSocietesCommerciales();
			if (vm.societes.length == 1) {
				vm.societes[0].selected = true;
				vm.societesSelected.push(vm.societes[0]);
				societeChange();
			}
		} catch (ex) {
			vm.notification.error(ex.data);
		} finally {
			vm.stopLoading();
		}
	}

	async function getSitesCommerciaux(idSociete) {
		vm.startLoading();
		try {
			vm.sites = await vm.PlanImpressionPrixService.getSitesCommerciaux(idSociete);
		} catch (ex) {
			vm.notification.error(ex.data);
		} finally {
			vm.stopLoading();
		}
	}

	async function getClientSelections(idSociete) {
		vm.startLoading();
		try {
			vm.clientSelections = await vm.PlanImpressionPrixService.getClientSelections(idSociete);
		} catch (ex) {
			vm.notification.error(ex.data);
		} finally {
			vm.stopLoading();
		}
	}

	async function getClients(tableState) {
		startLoading();

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		try {
			const idSociete = vm.societesSelected[0] ? vm.societesSelected[0].id : 0;
			const data = await PlanImpressionPrixService.getClients(filters, sorts, pagination, idSociete);
			vm.clients = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			vm.notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function societeChange() {
		await getSitesCommerciaux(vm.societesSelected[0].id);
		await getClientSelections(vm.societesSelected[0].id);
		await getClients();

		vm.sites.forEach((site) => {
			site.selected = true;
		}, vm);
	}

	async function clientsChange(opt) {
		vm.chantiers = null;
		const displayChantiers = [];

		if (opt == 'chantiersAvecPrix' && vm.chantierSansPrix) {
			vm.chantierSansPrix = false;
		}
		if (opt == 'chantierSansPrix' && vm.chantiersAvecPrix) {
			vm.chantiersAvecPrix = false;
		}

		vm.clients.forEach((client) => {
			client.selected = false;
		});

		if (vm.clientsSelected.length < 1) {
			//
		} else {
			for (let i = 0; i < vm.clientsSelected.length; i++) {
				const client = vm.clientsSelected[i];

				let chantiers = [];
				if (vm.chantiersAvecPrix) {
					const societeId = vm.societesSelected[0].id;
					const siteIds = vm.sitesSelected.length > 0 ? vm.sitesSelected.map((e) => e.id) : [0];
					chantiers = await PlanImpressionPrixService.getChantierPrixByClients(
						client.id,
						societeId,
						siteIds,
						vm.dateDebut.replace(/\//g, '-'),
						vm.dateFin.replace(/\//g, '-'),
						1
					);
				} else if (vm.chantierSansPrix) {
					const societeId = vm.societesSelected[0].id;
					const siteIds = vm.sitesSelected.length > 0 ? vm.sitesSelected.map((e) => e.id) : [0];
					const chantiersAvecPrix = await PlanImpressionPrixService.getChantierPrixByClients(
						client.id,
						societeId,
						siteIds,
						vm.dateDebut.replace(/\//g, '-'),
						vm.dateFin.replace(/\//g, '-'),
						1
					);
					const allchantiers = await PlanImpressionPrixService.getChantierByClients(client.id);

					allchantiers.forEach((chantier) => {
						const isFound = chantiersAvecPrix.find((e) => e.id === chantier.id);
						if (!isFound) {
							chantiers.push(chantier);
						}
					});
				} else {
					chantiers = await vm.PlanImpressionPrixService.getChantierByClients(client.id);
				}

				chantiers.forEach((chantier) => {
					const isFound = displayChantiers.find((e) => e.id === chantier.id);
					if (!isFound) {
						chantier.clientLibelle = client.libelle;
						chantier.idTypeclient = client.idTypeclient;
						displayChantiers.push(chantier);
					}
				});
			}
		}

		vm.chantiers = angular.copy(displayChantiers);
	}

	function anneeChange() {
		vm.dateDebut = vm.moment('01/01/' + vm.annee, 'DD/MM/YYYY').format('DD/MM/YYYY');
		vm.dateFin = vm.moment('31/12/' + vm.annee, 'DD/MM/YYYY').format('DD/MM/YYYY');

		if (vm.chantiersAvecPrix || vm.chantierSansPrix) {
			clientsChange();
		}
	}

	async function checkAllClients() {
		vm.clients.map(function (e) {
			e.selected = !vm.selected;
		}, vm);
	}

	async function checkAllChantiers() {
		vm.chantiers.map(function (e) {
			e.selected = !vm.selected;
		}, vm);
	}

	async function getPrintOptions() {
		const print = {
			societeId: null,
			sitesId: [],
			clientsId: [],
			chantiersId: [],
			annee: vm.annee,
			dateDebut: vm.dateDebut,
			dateFin: vm.dateFin,
			pdf: false,
			domaine: vm.domaine,
			templateId: null,
			isTarifParPage: vm.isTarifParPage,
			isRegroupementProduit: vm.isRegroupementProduit,
			isPrestation: vm.isPrestation
		};
		const idTypeClients = [];
		let type = 'client';
		let idSelected = 0;

		let dataReady = true;

		if (vm.societesSelected == null || vm.societesSelected.length < 1) {
			dataReady = false;
		} else {
			print.societeId = vm.societesSelected[0].id;
		}

		vm.sitesSelected.forEach((site) => {
			if (site.selected) {
				print.sitesId.push(site.id);
			}
		});

		if (print.sitesId.length > 0) {
			vm.clients.forEach((client) => {
				if (client.selected) {
					if (!idTypeClients.includes(client.idTypeclient)) {
						idTypeClients.push(client.idTypeclient);
					}
					print.clientsId.push(client.id);
				}
			}, vm);

			vm.chantiers.forEach((chantier) => {
				if (chantier.selected) {
					if (!idTypeClients.includes(chantier.idTypeclient)) {
						idTypeClients.push(chantier.idTypeclient);
					}
					print.chantiersId.push(chantier.id);
				}
			}, vm);

			if (print.clientsId.length < 1) {
				type = 'siteCommercial';
			}

			if (print.clientsId.length == 1) {
				type = 'client';
				idSelected = print.clientsId[0];
			} else {
				if (idTypeClients.length == 1) {
					type = 'typeClient';
					idSelected = idTypeClients[0];
				} else {
					type = 'aucun';
				}
			}

			if (print.chantiersId.length == 1) {
				type = 'chantier';
				idSelected = print.chantiersId[0];
			} else {
				if (idTypeClients.length == 1) {
					type = 'typeClient';
					idSelected = idTypeClients[0];
				} else {
					type = 'aucun';
				}
			}
		} else {
			//Aucun site commercial n'a été sélectionné pour traitement
			vm.notification.error("Aucun site commercial n'a été sélectionné pour traitement");
			dataReady = false;
		}
		return {
			print: print,
			type: type,
			idSelected: idSelected,
			dataReady: dataReady
		};
	}

	async function openModalFranco() {
		const that = vm;
		const printOptions = await getPrintOptions();
		const print = printOptions.print;
		const type = printOptions.type;
		const idSelected = printOptions.idSelected;
		const dataReady = printOptions.dataReady;
		if (dataReady) {
			if (!vm.isFranco) {
				vm.$uibModal
					.open({
						template:
							'<plan-impression-prix-franco modal-instance="$ctrl.uibModalInstance" date="$ctrl.date" domaine="$ctrl.type" url-domain-ids="$ctrl.urlDomainIds" url-domain-name="$ctrl.urlDomainName" id="$ctrl.idSelected"></plan-impression-prix-franco>',
						controller: [
							'$uibModalInstance',
							function ($uibModalInstance) {
								const $ctrl = this;
								$ctrl.uibModalInstance = $uibModalInstance;
								$ctrl.type = type;
								$ctrl.date = that.dateDebut;
								$ctrl.idSelected = idSelected;
								$ctrl.urlDomainIds = print.sitesId.length > 0 ? print.sitesId : [print.societeId];
								$ctrl.urlDomainName = print.sitesId.length > 0 ? 'siteCommercial' : 'societe';
							}
						],
						controllerAs: '$ctrl',
						size: 'xxl',
						backdrop: false
					})
					.result.then(
						async function (result) {
							if (result) {
								vm.prixTransport = result.prix;
								vm.libelleTransport = result.transportLibelle + ' - ' + result.benneLibelle + ' : ' + result.prix + ' €';
							} else {
								vm.isFranco = false;
							}
						},
						function () {
							// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
						}
					);
			} else {
				vm.prixTransport = null;
				vm.libelleTransport = '';
			}
		}
	}

	async function print() {
		const printOptions = await getPrintOptions();
		const print = printOptions.print;
		const dataReady = printOptions.dataReady;
		if (dataReady) {
			const templates = await TemplateImpressionService.getTemplateByDomaine(vm.domaine);
			let templateSelected = null;

			if (templates && templates.length > 0) {
				if (vm.isFranco && vm.prixTransport) {
					print.prixTransport = vm.prixTransport;
				}
				templateSelected = await TemplateImpressionService.downloadModal(vm.domaine);
				print.templateId = templateSelected.id;
				print.pdf = templateSelected.pdf;

				if (print.templateId !== undefined) {
					vm.startLoading();

					try {
						const response = await PlanImpressionPrixService.generate(print);
						download(response);
					} catch (e) {
						//console.log(e);
					}

					stopLoading();
				}
			}
		} else {
			//Données pour traitement incomplete, merci de bien vouloir contrôler votre saisie.
			vm.notification.error('Données incomplete pour traitement, merci de bien vouloir contrôler votre saisie.');
		}
	}

	function download(response, fileName) {
		if (response) {
			const data = response.data;
			let headers = response.headers;
			headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');
			try {
				const blob = new Blob([data], { type: 'application/octet-stream' + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);

				if (!fileName) {
					fileName = 'PlanPrix_' + Date.now();
				}

				if (contentType === 'application/pdf') {
					linkElement.setAttribute('download', fileName + '.pdf');
				} else if (contentType === 'application/octet-stream') {
					linkElement.setAttribute('download', fileName + '.docx');
				} else if (contentType === 'text/csv') {
					linkElement.setAttribute('download', fileName + '.csv');
				} else {
					linkElement.setAttribute('download', fileName + '.zip');
				}

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				//console.log(e);
			}
		}
	}

	function annuler() {
		if (vm.modalInstance) {
			vm.modalInstance.dismiss();
		} else {
			vm.$state.go('gestion.home');
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function openHelper() {
		vm.$uibModal.open({
			animation: true,
			size: 'lg',
			component: 'planImpressionPrixHelper'
		});
	}

	async function exporter() {
		const data = {
			societeId: null,
			sitesId: [],
			clientsId: [],
			chantiersId: [],
			dateDebut: vm.dateDebut,
			dateFin: vm.dateFin,
			isPrestation: vm.isPrestation,
			prixTransport: vm.prixTransport
		};

		if (vm.societesSelected && vm.societesSelected.length >= 1) {
			data.societeId = vm.societesSelected[0].id;
		}
		vm.sitesSelected.forEach((site) => {
			if (site.selected) {
				data.sitesId.push(site.id);
			}
		});

		data.clientsId = vm.clientsSelected.map((e) => e.id);
		vm.clients.forEach((client) => {
			if (client.selected) {
				data.clientsId.push(client.id);
			}
		}, vm);

		vm.chantiers.forEach((chantier) => {
			if (chantier.selected) {
				data.chantiersId.push(chantier.id);
			}
		}, vm);

		try {
			this.startLoading();
			const response = await this.PlanImpressionPrixService.exporter(data);
			this.download(response, 'ExportPrix_' + Date.now());
			this.stopLoading();
		} catch (e) {
			this.stopLoading();
		}
	}
}

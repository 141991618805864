import ConfigurationEmailFormController from './configuration.email.form.controller';
import ConfigurationEmailFormHtml from './configuration.email.form.html';
export default {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	// templateUrl: 'app/common/configuration-email/components/configuration-email-form/configuration.email.form.html',
	template: ConfigurationEmailFormHtml,
	controller: ConfigurationEmailFormController
};

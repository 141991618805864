import FacturesPrintController from './factures.print.controller';
import factureTemplate from './factures.print.html';

export default {
	bindings: {
		modalInstance: '=',
		source: '=',
		allFacturesOptions: '='
	},
	template: factureTemplate,
	controller: FacturesPrintController
};

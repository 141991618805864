import Lieu from './lieu.model';

export default class LieuFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'LieuxCommunicationService',
		'LieuxService',
		'notification',
		'$location',
		'$anchorScroll',
		'MassiaApplicationService',
		'RouterHistoryService',
		'MOPService'
	];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		LieuxCommunicationService,
		LieuxService,
		notification,
		$location,
		$anchorScroll,
		MassiaApplicationService,
		RouterHistoryService,
		MOPService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.LieuxCommunicationService = LieuxCommunicationService;
		this.LieuxService = LieuxService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.MassiaApplicationService = MassiaApplicationService;
		this.RouterHistory = RouterHistoryService;
		this.MOPService = MOPService;
	}

	$onInit() {
		this.MOPService.setMop([{ title: 'LIEUX', filename: 'Lieux.pdf', application: 'gestion' }]);
		// Propriétés
		this.lieu = {};
		this.loading = false;
		this.form = {
			entete: {},
			caracteristiques: {},
			niveaux: {}
		};

		// Configuration
		this.reset();
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateLieuTimeout);
		this.MOPService.resetMop();
	}

	async reset() {
		this.startLoading();

		const data = {
			id: this.$stateParams.id
		};

		if (data.id) {
			try {
				data.entete = await this.LieuxService.getLieuEnteteById(data.id);
				data.caracteristiques = await this.LieuxService.getLieuValeursCaracteristiquesAssociationsById(data.id);
				data.niveaux = await this.LieuxService.getLieuValeursNiveauxById(data.id);
			} catch (ex) {
				this.notification.error(ex.data);
				this.annuler();
			}
		}

		this.lieu = new Lieu(data);

		if (this.$stateParams.duplicate) {
			this.lieu.id = undefined;
			this.lieu.entete.code += '_copie';
		}

		this.ongletOpen = {
			isEnteteOpen: true,
			isCaracteristiqueOpen: false,
			isNiveauxHierarchiqueOpen: false
		};

		this.$timeout.cancel(this.updateLieuTimeout);
		this.updateLieuTimeout = this.$timeout(() => this.stopLoading());
	}

	async sauvegarder() {
		if (this.checkValidity()) {
			this.startLoading();

			try {
				let id = false;

				if (this.lieu.id) {
					await this.LieuxService.updateLieu(this.lieu);
					this.notification.success('LIEUX.UPDATED');
					id = this.lieu.id;
				} else {
					id = await this.LieuxService.createLieu(this.lieu);
					this.notification.success('LIEUX.CREATED');
				}

				return id;
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.stopLoading();
			}
		}
	}

	async appliquer() {
		this.RouterHistory.ignoreNextRoute();
		const id = await this.sauvegarder();

		if (id && this.lieu.id) {
			this.reset();
		} else if (id) {
			this.$state.go('lieux.edit', { id: id });
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();

		if (success) {
			this.annuler();
		}
	}

	async annuler() {
		if (this.RouterHistory && this.RouterHistory.history && this.RouterHistory.history.length > 0) {
			this.RouterHistory.back();
		} else {
			this.$state.go('lieux.list');
		}
	}

	checkValidity() {
		let validity = true;
		//on va seulement scroller et focus sur le premier onglet où il y a une erreur
		let firstScroll = true;

		if (!this.lieu.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('lieuEnteteValidations');
		}

		if (!this.lieu.caracteristiques.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiques');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueOpen = true;
			validity = false;
			this.$scope.$broadcast('lieuCaracteristiquesValidations');
		}

		// if (!this.lieu.niveaux.isValid()) {
		//      if (firstScroll) {
		//          this.scrollToOnglet('niveaux');
		//          firstScroll = false;
		//      }
		//
		//     this.ongletOpen.isNiveauxHierarchiqueOpen = true;
		//     validity = false;
		//     this.$scope.$broadcast('lieuNiveauxValidations');
		// }

		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	onUpdateEntete(entete) {
		this.lieu.entete = entete;
	}

	onUpdateCaracteristiques(caracteristiques) {
		this.lieu.caracteristiques = caracteristiques;
	}

	onUpdateNiveaux(niveaux) {
		this.lieu.niveaux = niveaux;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

import angular from 'angular';

export default class PrestationFormProduitLiesController {

    static $inject = ['$scope', 'PrestationsService', 'notification', '_'];

    constructor($scope, PrestationsService, notification) {
        this.$scope = $scope;
        this.PrestationsService = PrestationsService;
        this.notification = notification;
        this._ = _;
    }

    $onInit = () => {
        this.unregister = this.$scope.$watch(() => this.isOpen, async () => {
            if(this.isOpen) await this.loadProduitLies();
        }, true);
		this.isBySociete = 'true';
    }

    $onDestroy = () => {
        this.unregister();
    }

    async loadProduitLies() {
        this.startLoading();
        try {
            if (this.prestation) {
                this.produitLies = await this.PrestationsService.getProduitLies(this.prestation.entete.id, this.isBySociete);
                this.prestation.produitLies = this.produitLies;
                
                await this.initiateProduit();
            }               
        } catch (ex) {        
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    async initiateProduit() {
        const produits = await this.refreshFiltreProduits();
        this.inputFiltreProduitEntrees = angular.copy(produits);
        this.inputFiltreProduitSorties = angular.copy(produits);
    }

    resetProduit() {
        for (let index = 0; index < this.inputFiltreProduitEntrees.length; index++) {
            const element = this.inputFiltreProduitEntrees[index];
            element.selected = false;
        }
        for (let index = 0; index < this.inputFiltreProduitSorties.length; index++) {
            const element = this.inputFiltreProduitSorties[index];
            element.selected = false;
        }
    }

    async preselectFiltreProduits(produitLie) {
        this.resetProduit();
        for (let index = 0; index < produitLie.produitEntreeId.length; index++) {
            const element = produitLie.produitEntreeId[index];
            let filtre = this._.find(this.inputFiltreProduitEntrees, { id: element });
            if (filtre) {
                filtre.selected = true;
            }
        }

        for (let index = 0; index < produitLie.produitSortieId.length; index++) {
            const element = produitLie.produitSortieId[index];
            let filtre = this._.find(this.inputFiltreProduitSorties, { id: element });
            if (filtre) {
                filtre.selected = true;
            }
        }
    }

    async saveSelectedProduitEntrees(produitLie) {
        produitLie.produitEntreeId = [];
        for (var i = 0; i < this.outputFiltreProduitEntrees.length; i++) {
            let prod = this.outputFiltreProduitEntrees[i];
            produitLie.produitEntreeId.push(prod.id);
        }
    }

    async saveSelectedProduitSorties(produitLie) {
        produitLie.produitSortieId = [];
        for (var i = 0; i < this.outputFiltreProduitSorties.length; i++) {
            let prod = this.outputFiltreProduitSorties[i];
            produitLie.produitSortieId.push(prod.id);
        }
    }

    async refreshFiltreProduits() {
        let data;
        try {
            data = await this.PrestationsService.getProduits();
        } catch (ex) {
            data = [];
        }
        return data;
    }

    activeEditMode(produitLie) {
        produitLie.isEditMode = true;
        this.isEditMode = true;
        this.preselectFiltreProduits(produitLie);
    }

    async desactiveEditMode(produitLie) {
        produitLie.isEditMode = false;
        this.isEditMode = false;
        await this.loadProduitLies();
    }

    async updateProduitLie(produitLie) {
        produitLie.isEditMode = false;
        this.isEditMode = false;
        await this.PrestationsService.updateProduitLies(this.prestation.entete.id, produitLie, this.isBySociete);
        await this.loadProduitLies();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}

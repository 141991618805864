import validatorBuilder from 'validator-builder';

let commandeEnteteValidator = null;

export default class CommandeEntete {
	constructor(data) {
		data = data || {};
		this.id = data.id;
		this.societeId = data.societeId;
		this.code = data.code;
		this.etat = data.etat;
		this.typeId = data.typeId;
		this.referenceClient = data.referenceClient;
		this.isAnnuelle = data.isAnnuelle;
		this.clientId = data.clientId;
		this.nomClient = data.client;
		this.chantierId = data.chantierId;
		this.dateFinChantier = data.dateFinChantier;
		this.lieuId = data.lieuId;
		this.lieu = data.lieu;
		this.lieuCode = data.lieuCode;
		this.dateCreation = data.dateCreation;
		this.dateLivraisonDebut = data.dateLivraisonDebut;
		this.dateLivraisonFin = data.dateLivraisonFin;
		this.deviseId = data.deviseId;
		this.isFranco = data.isFranco;
		this.isTransporte = data.isTransporte;
		this.commentaire = data.commentaire;
		this.commandeLignes = data.commandeLignes;
		this.offreCode = data.offreCode;
		this.offreId = data.offreId;
		this.propositionId = data.propositionId;
		this.propositionLibelle = data.propositionLibelle;
		this.estCodeAuto = data.estCodeAuto;
		this.personneSuiviParId = data.personneSuiviParId;
		this.alerteResteLivrer = data.alerteResteLivrer;
		this.alerteDepassement = data.alerteDepassement;
		this.isBloquerDepassement = data.isBloquerDepassement;
		this.idModePaiement = data.idModePaiement;
	}

	isValid() {
		commandeEnteteValidator = commandeEnteteValidator || validatorBuilder.getInstanceFor('CommandeEntete');
		const validationResults = commandeEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}

import DocumentCE from './document.ce.model';
import { DocumentCeCaracteristiqueEssentielle } from './caracteristique/document.ce.caracteristique.model';
/* @ngInject */
export default class DocumentCEFormController {
	constructor(
		_,
		$scope,
		$timeout,
		$state,
		$stateParams,
		DocumentsCECommunicationService,
		DocumentsCEService,
		notification,
		$location,
		$anchorScroll,
		MassiaApplicationService,
		$uibModal,
		TemplateImpressionService,
		$translate,
		MassiaRightsService,
		toastr,
		MOPService
	) {
		this._ = _;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.DocumentsCECommunicationService = DocumentsCECommunicationService;
		this.DocumentsCEService = DocumentsCEService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$uibModal = $uibModal;
		this.TemplateImpressionService = TemplateImpressionService;
		this.$translate = $translate;
		this.MassiaRightsService = MassiaRightsService;
		this.toastr = toastr;
		this.MOPService = MOPService;
	}

	$onInit() {
		this.MOPService.setMop([
			{ title: 'DOCUMENTS_CE.BREADCRUMBS.DOCUMENTS_CE_LIST', filename: 'DocumentCE.pdf', application: 'gestion' },
			{ title: 'NORMES.BREADCRUMBS.NORMES_LIST', filename: 'Normes.pdf', application: 'gestion' }
		]);
		this.app = this.MassiaApplicationService.getApplication();
		// Propriétés
		this.docce = {};
		this.loading = false;
		//* vérification de la cohérence entre le certificat et les normes séléctionnées
		this.validity = true;
		this.normeserror = [];
		this.form = {
			entete: {},
			caract: {}
		};

		this.selectedNormes = [];
		// Configuration
		this.reset();
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateDocumentCETimeout);
		this.MOPService.resetMop();
	}

	async reset() {
		this.startLoading();

		const data = {
			id: this.$stateParams.id
		};

		if (data.id) {
			try {
				data.entete = await this.DocumentsCEService.getDocumentCEEnteteById(data.id);
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
				this.annuler();
			}
		}

		// cree l objet Document CE lui meme creant les objets
		// DocumentCEEntete
		this.docce = new DocumentCE(data);
		if (data.entete && data.entete.certif) {
			this.certif = data.entete.certif;
		}
		if (this.$stateParams.duplicate) {
			console.log('this.docce', this.docce);
			this.docce.id = undefined;
			this.docce.entete.libelle += '_copie';
		}

		this.ongletOpen = {
			isEnteteOpen: true,
			isCaractOpen: true
		};

		this.$timeout.cancel(this.updateDocumentCETimeout);
		this.updateDocumentCETimeout = this.$timeout(() => this.stopLoading());
	}

	async sauvegarder() {
		if (this.checkValidity() && this.validity) {
			this.startLoading();
			const newNormeIds = [];
			const certif = [];

			const that = this;
			this.docce.entete.normesIds.forEach((value) => {
				if (value.id) {
					newNormeIds.push(value.id);
				}

				if (that.docce.caracteristique && that.docce.caracteristique.caracteristiqueEssentielle) {
					that.docce.caracteristique.caracteristiqueEssentielle.forEach((element) => {
						if (element.tableauZa.idNorme === value.id) {
							certif.push({
								idNorme: value.id,
								certif: element.certification || false
							});
						}
					});
				}
			});
			this.docce.caracteristique.certif = certif;
			this.docce.entete.normesIds = newNormeIds;
			try {
				let id = false;
				const valeurs = await this.getValeurs();
				if (this.docce.id) {
					await this.DocumentsCEService.updateDocumentCE(this.docce, valeurs);
					id = this.docce.id;
					//await this.sauvegarderCarEss(id);
					this.notification.success('DOCUMENTS_CE.UPDATED');
				} else {
					id = await this.DocumentsCEService.createDocumentCE(this.docce, valeurs);
					//await this.sauvegarderCarEss(id);
					this.notification.success('DOCUMENTS_CE.CREATED');
				}

				return id;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
				return false;
				/** pour l'update
                 // SL si ex.status == 400, le site ne peut etre supprime
            this.notification.error(ex.data);
            this.reinit(); */
			} finally {
				this.stopLoading();
			}
		}
		if (!this.validity) {
			for (let i = 0; i < this.normeserror.length; i++) {
				const element = this.normeserror[i];
				const msg = this.$translate.instant('DOCUMENTS_CE.NORME_ERROR', { norme: element.libelle });
				this.toastr.error(msg);
			}
		}
	}

	async appliquer() {
		const id = await this.sauvegarder();
		if (id && this.docce.id) {
			this.reset();
		} else if (id) {
			if (this.MassiaApplicationService.getApplication() == 'gestion') {
				this.$state.go('documents-ce.edit', { id: id });
			} else {
				this.$state.go('documents-ce-labo.edit', { id: id });
			}
		}
	}

	async getValeurs() {
		const valeurs = [];
		if (this.docce.caracteristique && this.docce.caracteristique.caracteristiqueEssentielle) {
			for (let j = 0; j < this.docce.caracteristique.caracteristiqueEssentielle.length; j++) {
				const element = this.docce.caracteristique.caracteristiqueEssentielle[j];
				if (element.carEss) {
					for (let i = 0; i < element.carEss.length; i++) {
						const caract = element.carEss[i];
						if (caract.essais && caract.caractEssentielle && caract.tableauZa && !caract.hide) {
							const data = new DocumentCeCaracteristiqueEssentielle({
								idValeur: caract.idValeur,
								idDocCE: Number(this.docce.id),
								idNorme: caract.tableauZa.idNorme,
								idEssai: caract.essais.id,
								valeur: caract.valeur,
								typeValeur: caract.typeValeur,
								idCarEss: caract.caractEssentielle.id || null,
								idCategorie: caract.idCategorie || 0,
								ordre: i + 1
							});
							valeurs.push(data);
						}
					}
				}
			}
		}
		return valeurs;
	}

	async confirmer() {
		const success = await this.sauvegarder();

		if (success) {
			//this.sauvegarderCarEss(success);
			this.annuler();
		}
	}

	async annuler() {
		if (this.MassiaApplicationService.getApplication() == 'gestion') {
			this.$state.go('documents-ce.list');
		} else {
			this.$state.go('documents-ce-labo.list');
		}
	}

	checkValidity() {
		let validity = true;

		//on va seulement scroller et focus sur le premier onglet où il y a une erreur
		let firstScroll = true;

		if (!this.docce.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('documentCEEnteteValidations');
		}
		return validity;
	}

	async openPrint(isNormeParPage) {
		if (this.docce.id) {
			try {
				const res = await this.TemplateImpressionService.downloadModal('docce');
				const template = await this.DocumentsCEService.print(this.docce.id, 0, res.filename, isNormeParPage);
				template.pdf = res.pdf;
				if (res === 'cancel') {
					throw res;
				}
				this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
					autoDismiss: false
				});
				await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'docce');
			} catch (err) {
				//if(err !== "cancel") notification.error(err.data);
				console.error(err);
			}
		}
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	onUpdateEntete(entete) {
		this.docce.entete = entete;
	}

	onUpdateCaracteristique(caracteristique) {
		this.docce.caracteristique = caracteristique;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	test() {
		console.log(this.docce);
	}
}

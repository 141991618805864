SitesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'SitesService',
	'notification',
	'MassiaApplicationService',
	'DataShareService',
	'$transitions',
	'MOPService',
	'$uibModal',
	'ProduitsService',
	'FamillesService',
	'_'
];

/**
 *
 * @param {ng.ui.bootstrap.IModalService} $uibModal
 */
export default function SitesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	SitesService,
	notification,
	MassiaApplicationService,
	DataShareService,
	$transitions,
	MOPService,
	$uibModal,
	ProduitsService,
	FamillesService,
	_
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	vm.selectedSiteId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.sites = [];
	vm.savedPagination = {};
	vm.savedPagination.entity = 'pagination';
	vm.DataShareService = DataShareService;
	vm.MOPService = MOPService;
	vm.ProduitsService = ProduitsService;
	vm.FamillesService = FamillesService;

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectSite = selectSite;
	vm.isSiteSelected = isSiteSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadSites = loadSites;
	vm.deleteSite = deleteSite;
	vm.setSelected = setSelected;
	vm.eraseAllFilters = eraseAllFilters;
	vm.getDomaine = getDomaine;
	vm.getDomaineLibelle = getDomaineLibelle;
	vm.getUrl = getUrl;
	vm.exporter = exporter;
	vm.siteGenre = null;
	vm.getApplication = getApplication;
	vm.showEntites = showEntites;
	vm.afficheJoursFeries = false;
	vm.isFiltered = true;
	vm.editDistancesCo2 = editDistancesCo2;
	vm._ = _;
	vm.listClientsProducteurs = [];

	function init() {
		vm.genreSite = $stateParams.genre;
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}

		getGenres();

		vm.afficheJoursFeries =
			(MassiaApplicationService.getApplication() == 'performance' && $stateParams.genre == 'producteurs') ||
			(MassiaApplicationService.getApplication() == 'laboratoire' && $stateParams.genre == 'laboratoires') ||
			(MassiaApplicationService.getApplication() == 'gestion' && $stateParams.genre == 'commerciaux') ||
			(MassiaApplicationService.getApplication() == 'qse' && $stateParams.genre == 'auditeurs');

		if (vm.genreSite == 'commerciaux') {
			vm.MOPService.setMop([
				{ title: 'SITES.BREADCRUMBS.SITES_COMMERCIAUX_LIST', filename: 'SitesCommerciaux.pdf', application: 'gestion' },
				{ title: 'SOCIETES.FILTRES_SOC_SITES', filename: 'FiltresSocietesSites.pdf', application: 'gestion' },
				{ title: 'FACTURES.BREADCRUMBS.FACTURES_PONT', filename: 'PontComptable.pdf', application: 'gestion' }
			]);
		} else {
			vm.MOPService.setMop([{ title: 'FACTURES.BREADCRUMBS.FACTURES_PONT', filename: 'PontComptable.pdf', application: 'gestion' }]);
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc() {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	async function getGenres() {
		vm.genres = await SitesService.getGenres();
	}

	function setSelected(site) {
		if (site && site.id) {
			vm.selectedSiteId = $stateParams.id;
			vm.lastSelected = vm.selectedSiteId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedSiteId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function getApplication() {
		return MassiaApplicationService.getApplication();
	}

	function getUrlListe() {
		switch ($stateParams.genre) {
			case 'commerciaux':
				return 'sites.list';
			case 'clients':
				return 'clients.list';
			case 'producteurs':
				return 'producteurs.list';
			case 'fournisseurs':
				return 'fournisseurs.list';
			case 'transporteurs':
				return 'transporteurs.list';
			case 'laboratoires':
				return 'laboratoires.list';
			case 'proprietaires':
				return 'proprietaires.list';
			case 'locataires':
				return 'locataires.list';
			case 'auditeurs':
				return 'auditeurs.list';
			default:
				return 'sites.list';
		}
	}

	function getUrl() {
		switch ($stateParams.genre) {
			case 'commerciaux':
				return 'sites';
			case 'clients':
				return 'clients';
			case 'producteurs':
				return 'producteurs';
			case 'fournisseurs':
				return 'fournisseurs';
			case 'transporteurs':
				return 'transporteurs';
			case 'laboratoires':
				return 'laboratoires';
			case 'proprietaires':
				return 'proprietaires';
			case 'locataires':
				return 'locataires';
			case 'auditeurs':
				return 'auditeurs';
			default:
				return 'sites';
		}
	}

	function getUrlDetail() {
		switch ($stateParams.genre) {
			case 'commerciaux':
				return 'sites.list.detail';
			case 'clients':
				return 'clients.list.detail';
			case 'producteurs':
				return 'producteurs.list.detail';
			case 'fournisseurs':
				return 'fournisseurs.list.detail';
			case 'transporteurs':
				return 'transporteurs.list.detail';
			case 'laboratoires':
				return 'laboratoires.list.detail';
			case 'proprietaires':
				return 'proprietaires.list.detail';
			case 'locataires':
				return 'locataires.list.detail';
			case 'auditeurs':
				return 'auditeurs.list.detail';
			default:
				return 'sites.list.detail';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === getUrlDetail() && $stateParams && $stateParams.id;
	}

	function selectSite(site) {
		if (site && site.id) {
			if (vm.selectedSiteId !== site.id) {
				vm.selectedSiteId = site.id;
				showDetail(site.id);
			} else {
				vm.selectedSiteId = undefined;
				closeDetail();
			}
		}
	}

	function getDomaine() {
		switch ($stateParams.genre) {
			case 'commerciaux':
				return 'siteutil';
			case 'clients':
				return 'siteclient';
			case 'producteurs':
				return 'siteprod';
			case 'fournisseurs':
				return 'sitefourn';
			case 'transporteurs':
				return 'sitetransp';
			case 'laboratoires':
				return 'sitelabo';
			case 'proprietaires':
				return 'siteproprio';
			case 'locataires':
				return 'sitelocat';
			case 'auditeurs':
				return 'siteaudit';
			default:
				return 'sites';
		}
	}

	function getDomaineLibelle() {
		switch ($stateParams.genre) {
			case 'commerciaux':
				return $translate.instant('SITES.BREADCRUMBS.SITES_COMMERCIAUX_LIST');
			case 'clients':
				return $translate.instant('SITES.BREADCRUMBS.SITES_CLIENTS_LIST');
			case 'producteurs':
				return $translate.instant('SITES.BREADCRUMBS.SITES_PRODUCTEURS_LIST');
			case 'fournisseurs':
				return $translate.instant('SITES.BREADCRUMBS.SITES_FOURNISSEURS_LIST');
			case 'transporteurs':
				return $translate.instant('SITES.BREADCRUMBS.SITES_TRANSPORTEURS_LIST');
			case 'laboratoire':
				return $translate.instant('SITES.BREADCRUMBS.SITES_LABORATOIRES_LIST');
			case 'proprietaires':
				return $translate.instant('SITES.BREADCRUMBS.SITES_PROPRIETAIRES_LIST');
			case 'locataires':
				return $translate.instant('SITES.BREADCRUMBS.SITES_LOCATAIRES_LIST');
			case 'auditeurs':
				return $translate.instant('SITES.BREADCRUMBS.SITES_AUDITEURS_LIST');
			default:
				return $translate.instant('SITES.BREADCRUMBS.SITES_LIST');
		}
	}

	function isSiteSelected(site) {
		return site && site.id && vm.selectedSiteId === site.id;
	}

	function showDetail(siteId) {
		const route = getUrlDetail();
		const genre = $stateParams.genre;
		$state.go(route, { genre: genre, id: siteId });
	}

	function closeDetail() {
		$state.go(getUrlListe());
	}

	function showEntites(idSite) {
		//$state.go('producteurs.points-mesure', { 'idSite':idSite, genre: this.params.genre });
		$state.go('points-mesure.list', { idSite: idSite });
	}

	async function loadSites(tableState) {
		startLoading();

		// gestion de la sauvegarde et de la restitution de la pagination
		const objectToManage = {};
		objectToManage.tableState = tableState;
		objectToManage.savedPagination = vm.savedPagination;
		objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
		objectToManage.itemsByPage = vm.itemsByPage;
		const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
		if (resultObject) {
			tableState = resultObject.tableState;
			vm.savedPagination = resultObject.savedPagination;
			vm.itemsByPageOptions = resultObject.itemsByPageOptions;
			vm.itemsByPage = resultObject.itemsByPage;
		}

		if ($state && $state.current && $state.current.name !== getUrlListe()) {
			$state.go(getUrlListe());
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.sites = [];
		try {
			let data = [];
			switch ($stateParams.genre) {
				case 'commerciaux':
					data = await SitesService.getSitesCommerciaux(filters, sorts, pagination);
					vm.siteGenre = 1;
					break;
				case 'clients':
					data = await SitesService.getSitesClients(filters, sorts, pagination, vm.isFiltered);
					vm.siteGenre = 2;
					break;
				case 'producteurs':
					data = await SitesService.getSitesProducteurs(
						MassiaApplicationService.getApplication(),
						filters,
						sorts,
						pagination,
						vm.isFiltered
					);
					vm.siteGenre = 4;
					break;
				case 'transporteurs':
					data = await SitesService.getSitesTransporteurs(filters, sorts, pagination, vm.isFiltered);
					vm.siteGenre = 16;
					break;
				case 'fournisseurs':
					data = await SitesService.getSitesFournisseurs(filters, sorts, pagination, vm.isFiltered);
					vm.siteGenre = 8;
					break;
				case 'laboratoires':
					data = await SitesService.getSitesLaboratoires(filters, sorts, pagination);
					vm.siteGenre = 32;
					break;
				case 'proprietaires':
					data = await SitesService.getSitesProprietaires(filters, sorts, pagination);

					vm.siteGenre = 64;
					break;
				case 'locataires':
					data = await SitesService.getSitesLocataires(filters, sorts, pagination);
					vm.siteGenre = 128;
					break;
				case 'auditeurs':
					data = await SitesService.getSitesAuditeurs(filters, sorts, pagination);
					vm.siteGenre = 256;
					break;
				default:
					data = await SitesService.getSites(filters, sorts, pagination);
					break;
			}
			vm.sites = data.items;
			const filter = [
				{ criterion: 'genre', value: 6, type: 'Eq' },
				{ criterion: 'isClient', value: true, type: 'Eq' },
				{ criterion: 'isProducteur', value: true, type: 'Eq' }
			];
			const sort = [{ criterion: 'libelle', isDescending: true }];
			vm.listClientsProducteurs = await SitesService.getSitesProducteurs(MassiaApplicationService.getApplication(), filter, sort, null, false);
			vm.listClientsProducteurs = vm.listClientsProducteurs.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteSite(site) {
		if (site && site.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('SITES.DELETE.TITLE', { code: site.libelle }),
				modalMsg: $translate.instant('SITES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await SitesService.deleteSiteById(site.id);
					notification.success($translate.instant('SITES.DELETE.SUCCESS'));

					vm.selectedSiteId = undefined;

					if (vm.params.id) {
						vm.state.go(getUrlListe());
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					loadSites();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_sites';
			let resultat = undefined;
			try {
				resultat = await SitesService.exportListeSites(modeImpression, vm.siteGenre, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('SITES.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
					//console.log(ex);
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	function eraseAllFilters() {
		loadSites();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}

	async function editDistancesCo2() {
		const modalInstance = $uibModal.open({
			component: 'editDistancesModal',
			animation: true,
			size: 'xxl',
			resolve: {
				listClientsProducteurs: () => vm.listClientsProducteurs
			}
		});
		await modalInstance.result;
	}
}

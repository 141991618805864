import { Component } from '../../../core/decorators';
import mopServiceButtonTemplate from './mop.service.button.tpl.html';

@Component({
	selector: 'mop-service-button',
	template: mopServiceButtonTemplate
})
export class MopServiceButtonController {
	constructor(MOPService) {
		this.MOPService = MOPService;
	}

	$onInit() {}

	openFirstMOP() {
		let firstMOP = this.MOPService.currentMop[0];
		this.MOPService.downloadMOPfile(firstMOP.filename, firstMOP.application);
	}
}

MopServiceButtonController.$inject = ['MOPService'];

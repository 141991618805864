export default function Configure($stateProvider) {
	$stateProvider.state('configuration-email', {
		parent: 'common',
		url: '/configuration-email',
		views: {
			common: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
		}
	});

	$stateProvider.state('configuration-email.list', {
		url: '/list',
		template: '<configuration-email-list></configuration-email-list>',
		rights: { domain: 'configemail', right: 'read' },
		ncyBreadcrumb: {
			label: '{{ "CONFIGURATION_EMAIL.BREADCRUMBS.LIST" | translate}}'
		},
		search: 'CONFIGURATION_EMAIL.BREADCRUMBS.LIST'
	});
}
Configure.$inject = ['$stateProvider'];

import L from 'leaflet/dist/leaflet';
import 'leaflet-easyprint';
import 'leaflet-measure/dist/leaflet-measure.fr';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.min';

export default class DapAttenteFormController {
	/* @ngInject */
	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		DAPsCommunicationService,
		DAPsService,
		DocumentsService,
		RouterHistoryService,
		ChantiersService,
		notification,
		$location,
		$anchorScroll,
		$translate,
		$uibModal,
		moment,
		ModalService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.DAPsCommunicationService = DAPsCommunicationService;
		this.DAPsService = DAPsService;
		this.DocumentsService = DocumentsService;
		this.chantierService = ChantiersService;
		this.historyService = RouterHistoryService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$translate = $translate;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this.ModalService = ModalService;
		this.currDap = null;
		this.loading = false;
		this.isOpenForm = true;
		this.map = {};
		this.haveCreation = false;
		this.chantiers = [];
		this.transporteurs = [];
		this.producteurs = [];
		this.loading = true;
		this.isChantierCreated = false;
		this.oldChantier = {};
		this.untouchedDap = {};
		this.tmp = {
			chantier: []
		};
	}

	async $onInit() {
		this.isOpen = true;
		this.currDap = await this.DAPsService.GetDapAttenteById(this.$stateParams.id);
		console.log('🚀 ~ DapAttenteFormController ~ $onInit ~ this.currDap:', this.currDap);

		//rustine
		this.currDap.latitude = this.currDap.chantier.latitude;
		this.currDap.longitude = this.currDap.chantier.longitude;
		// fin rustine

		this.untouchedDap = angular.copy(this.currDap);

		if (this.currDap.statut === 1) {
			this.currDap.statut = 2;
			this.DAPsService.UpdateDapAttenteAsSeen(this.currDap.id);
		}

		this.isChantierCreated = !!this.$stateParams.chantierId;
		if (this.isChantierCreated) {
			this.oldChantier = this.currDap.chantier;
			console.log(this.$stateParams.chantierId);
			this.currDap.chantier.id = this.$stateParams.chantierId;
		}

		this.historyService.data = this.currDap;
		this.refresh();
	}

	async getProducteurs() {
		try {
			this.producteurs = await this.DAPsService.getProducteursBySite(this.currDap.demandeurId);
			if (this.currDap.producteur.id === 0) {
				if (this.$stateParams.producteurId) {
					this.currDap.producteur = this.producteurs.find((x) => x.id === this.$stateParams.producteurId);
				} else {
					this.producteurs.push(this.currDap.producteur);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async saveSelectedProducteur(producteur) {
		if (this.currDap.producteur === null || producteur.id !== this.currDap.producteur.id) {
			this.currDap.producteur = producteur;
		} else {
			this.currDap.producteur = null;
			this.producteurs.map((x) => {
				if (x.id === producteur.id) {
					x.selected = false;
					return x;
				}
			});
		}

		await this.checkForCreation();
	}

	async getTransporteurs() {
		try {
			const transporteurs = await this.DAPsService.getTransporteursBySite(this.currDap.siteAccueilId);
			this.transporteurs = transporteurs;
			if (this.$stateParams.transporteurId) {
				const t = this.transporteurs.find((x) => x.id === this.$stateParams.transporteurId);
				this.currDap.transporteur[this.$stateParams.transporteurIndex] = t;
			} else {
				this.currDap.transporteur.map((x) => {
					if (x.id === 0) {
						this.transporteurs.push(x);
					}
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	async saveSelectedTransporteurs(transporteur) {
		if (this.currDap.transporteur.some((x) => x.id === transporteur.id && x.libelle === transporteur.libelle)) {
			this.currDap.transporteur = this.currDap.transporteur.filter((x) => x.id !== transporteur.id && x.libelle !== transporteur.libelle);
		} else {
			this.currDap.transporteur.push(transporteur);
		}

		await this.checkForCreation();
	}

	// async saveSignatureTransporteur(transporteur) {
	// 	//TODO Permettre l'utilisation des coches :D
	// }

	async getChantier() {
		try {
			this.chantiers = await this.DAPsService.getChantiersBySite(this.currDap.demandeurId);
			if (this.currDap.chantier.id === 0) {
				this.currDap.chantier.code = 'A CREER';
				this.chantiers.push(this.currDap.chantier);
			} else {
				const documents = this.currDap.chantier.documents;
				this.currDap.chantier = this.chantiers.find((x) => x.id === this.currDap.chantier.id);
				this.currDap.chantier.documents = documents;
			}
		} catch (error) {
			console.error(error);
		}
	}

	async saveSelectedChantier(chantier) {
		if (this.currDap.chantier === null || chantier.id !== this.currDap.chantier.id) {
			this.currDap.chantier = chantier;
		} else {
			this.currDap.chantier = null;
			this.chantiers.map((x) => {
				if (x.id === chantier.id) {
					x.selected = false;
					return x;
				}
			});
		}

		await this.checkForCreation();
	}

	async isFormInvalid() {
		if (this.isProducteurToCreate()) {
			return true;
		}
		if (this.isChantierToCreate()) {
			return true;
		}
		if (this.haveTransporteursToCreate()) {
			return true;
		}
		if (!this.currDap.dateDebut) {
			return true;
		}
		if (!this.currDap.dateFin) {
			return true;
		}

		return false;
	}

	haveTransporteursToCreate() {
		return this.currDap.transporteur.length === 0 || this.currDap.transporteur.some((x) => x.Id > 0);
	}

	isChantierToCreate() {
		return !this.currDap.chantier || this.currDap.chantier.id === 0;
	}

	isProducteurToCreate() {
		return !this.currDap.producteur || this.currDap.producteur.id === 0;
	}

	async checkForCreation() {
		this.haveCreation = await this.isFormInvalid();
	}

	async refresh() {
		await this.getChantier();
		await this.getTransporteurs();
		await this.getProducteurs();

		await this.checkForCreation();
		this.loading = false;
		this.initCarte();
	}

	getContamination(chantier) {
		if (chantier.isContamine) {
			return 2;
		} else if (chantier.isPotentielContamine) {
			return 1;
		}

		return 0;
	}

	toDate(date) {
		const dateStr = date;
		const parts = dateStr.split('/');
		const dateObj = new Date(parts[2], parts[1] - 1, parts[0]);
		return dateObj;
	}

	async save() {
		this.loading = true;

		const data = {};
		data.id = this.currDap.id;

		if (this.currDap.chantier !== this.untouchedDap.chantier) {
			data.chantier = this.currDap.chantier;
		}

		if (this.currDap.transporteur !== this.untouchedDap.transporteur) {
			data.transporteur = this.currDap.transporteur;
		}

		if (this.currDap.producteur != this.untouchedDap.producteur) {
			data.producteur = this.currDap.producteur;
		}

		if (this.currDap.dateFin != this.untouchedDap.dateFin) {
			data.dateFin = this.toDate(this.currDap.dateFin);
		}

		if (this.currDap.dateDebut != this.untouchedDap.dateDebut) {
			data.dateDebut = this.toDate(this.currDap.dateDebut);
		}

		try {
			this.DAPsService.UpdateDapAttente(data);
			this.notification.success(this.$translate.instant('DAPS.ATTENTE.SAVE_SUCCESS'));
			this.loading = false;
		} catch (error) {
			this.notification.error(this.$translate.instant('DAPS.ATTENTE.SAVE_ERROR'));
			this.loading = false;
		}

		await this.checkForCreation();
	}

	async openValidationModal(isRefus) {
		this.$uibModal.open({
			animation: true,
			size: 's',
			component: 'dapValidationModal',
			resolve: {
				dap: () => this.currDap,
				isRefus: () => isRefus
			},
			controllerAs: '$ctrl'
		});
	}

	async downloadDocument(id, filename) {
		//TODO Download Document
		const resultat = await this.DAPsService.getDapAttenteFileById(id);
		const data = resultat.data;
		let headers = resultat.headers;

		headers = headers();
		console.log(data, headers);
		const contentType = headers['content-type'];

		const linkElement = document.createElement('a');
		try {
			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', filename + '.pdf');

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
		} catch (ex) {
			console.log(ex);
		}
	}

	async validate() {
		try {
			this.openValidationModal(false);
		} catch (error) {
			console.log(error);
		}
	}

	async reject() {
		try {
			this.openValidationModal(true);
		} catch (error) {
			this.notification.error(this.$translate.instant('DAPS.ATTENTE.REJ_ERROR'));
			console.log(error);
		}
	}

	async restore() {
		try {
			await this.DAPsService.UpdateDapAttenteAsSeen(this.$stateParams.id);
			this.notification.success(this.$translate.instant('DAPS.ATTENTE.RESTORE_TOAST'));
			this.currDap.statut = 2;
		} catch (error) {
			this.notification.success(error.error);
		}
	}

	annuler() {
		if (!this.historyService.back({ fromDapDetail: true })) {
			this.$state.go('daps.list');
		}
	}

	async initCarte() {
		this.zoom = 5;
		this.longitude = 3.5;
		this.latitude = 45.5;
		if (this.currDap.longitude) {
			this.longitude = this.currDap.longitude;
		}
		if (this.currDap.latitude) {
			this.latitude = this.currDap.latitude;
		}
		if (this.currDap.longitude && this.currDap.latitude) {
			this.zoom = 15;
		}

		$('#map', async () => {
			this.map = L.map('map').setView([this.latitude, this.longitude], this.zoom); //par défaut

			this.map.scrollWheelZoom.disable();

			//Couches
			const Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				//z:niveau de zoom, x et y coordonnées du pavé
				attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				maxZoom: 20 //zoom maximum de la carte
			});
			Mapnik.addTo(this.map);

			this.blueMarker = L.icon({
				iconUrl: 'assets/img/pin_GO.png',
				iconSize: [64, 64], // size of the icon
				iconAnchor: [8, 55] // point of the icon which will correspond to marker's location
			});
			this.marqueurSite = L.marker([this.latitude, this.longitude], {
				icon: this.blueMarker,
				draggable: false /*, class:'leaflet-marker-pane'*/
			});
			this.marqueurSite.addTo(this.map);
		});
	}
}

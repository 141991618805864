import ConfigurationEmailForm from './configuration.email.form.model';
export default class ConfigurationEmailFormController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'ModalService',
		'ConfigurationEmailService',
		'notification',
		'$translate',
		'$filter',
		'_',
		'$uibModal'
	];
	constructor($scope, $state, $stateParams, ModalService, ConfigurationEmailService, notification, $translate, $filter, _, $uibModal) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ModalService = ModalService;
		this.ConfigurationEmailService = ConfigurationEmailService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this._ = _;
		this.$uibModal = $uibModal;
		this.configuration = new ConfigurationEmailForm();
		this.loading = false;
		this.configurationEmail = null;
		this.domaineTypeLibelle = '';
		this.balises = [];
		this.activeField = null;
	}

	async $onInit() {
		await this.loadConfiguration(this.resolve.idconfig);
		this.domaineTypeLibelle = this.$translate.instant(
			'CONFIGURATION_EMAIL.DOMAINE_TYPE_ENUM.' + this.configuration.codeDomaine.toUpperCase() + '.' + this.configuration.domaineType
		);

		await this.loadConfigurationBalises(this.configuration.idDomaine, this.configuration.domaineType);
	}

	async loadConfiguration(idconfig) {
		this.startLoading();
		try {
			const config = await this.ConfigurationEmailService.getConfigurationEmailById(idconfig);
			if (config) {
				this.configuration = new ConfigurationEmailForm(config);
			}
		} catch (error) {
			this.notification.error('CONFIGURATION_EMAIL.FORM.CONFIGURATION_NOT_FOUND');
		}
		this.stopLoading();
	}

	async loadConfigurationBalises(idDomaine, domaineType) {
		try {
			const balisesList = await this.ConfigurationEmailService.getConfigurationsEmailBalises(idDomaine, domaineType);

			this.balises = balisesList.filter((x) => {
				if (!this.configuration.isForPortail && x.isForPortail) {
					return false;
				}
				return true;
			});
		} catch (error) {
			console.error('Failed to load configuration balises:', error);
		}
	}

	setDefaultMessageConfiguration() {
		this.configuration.titre = this.configuration.titreDefault;
		this.configuration.message = this.configuration.messageDefault;
	}

	setDefaultAlias() {
		this.configuration.smtpAlias = this.configuration.smtpAliasDefault;
	}

	setActiveField(fieldName) {
		this.activeField = fieldName;
	}

	insertBalise(baliseCode) {
		baliseCode = '{' + baliseCode + '} ';
		const field = document.getElementById(this.activeField);
		if (field) {
			var startPos = field.selectionStart;
			var endPos = field.selectionEnd;
			var text = field.value;

			field.value = text.substring(0, startPos) + baliseCode + text.substring(endPos, text.length);

			// Mettre à jour ng-model
			if (this.activeField === 'titre') {
				this.configuration.titre = field.value;
			} else if (this.activeField === 'message') {
				this.configuration.message = field.value;
			}

			// Placer le curseur après la balise insérée
			field.selectionStart = field.selectionEnd = startPos + baliseCode.length;

			// Nécessaire pour mettre à jour l'interface utilisateur
			this.$scope.$apply();

			// Redonner le focus au champ
			field.focus();
		}
	}

	async save() {
		if (this.checkValidity()) {
			const form = {
				idSociete: this.configuration.idSociete,
				idDomaine: this.configuration.idDomaine,
				titre: this.configuration.titre,
				message: this.configuration.message,
				smtpAlias: this.configuration.smtpAlias
			};

			await this.ConfigurationEmailService.updateConfigurationEmail(this.configuration.id, form);

			this.notification.success('CONFIGURATION_EMAIL.FORM.CONFIGURATION_SAVED');
			this.ok();
		} else {
			return this.notification.error('CONFIGURATION_EMAIL.FORM.VALIDATION_ERROR');
		}
	}

	ok() {
		this.close();
	}

	cancel() {
		this.dismiss();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	checkValidity() {
		let validity = true;
		if (!this.configuration.isValid()) {
			validity = false;
		}
		return validity;
	}
}

export default class FactureReglementsController {
	static $inject = [
		'$stateParams',
		'FacturesCommunicationService',
		'ReglementsService',
		'notification',
		'$translate',
		'ModalService',
		'FacturesService',
		'moment',
		'globalizationManagementService'
	];

	constructor(
		$stateParams,
		FacturesCommunicationService,
		ReglementsService,
		notification,
		$translate,
		ModalService,
		FacturesService,
		moment,
		globalizationManagementService
	) {
		this.$stateParams = $stateParams;
		this.FacturesCommunicationService = FacturesCommunicationService;
		this.ReglementsService = ReglementsService;
		this.notification = notification;
		this.$translate = $translate;
		this.ModalService = ModalService;
		this.FacturesService = FacturesService;
		this.moment = moment;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		await this.getFacture();
		await this.getModesPaiement();
		await this.getDevises();
		await this.loadReglements();
		this.showAddLine = false;
	}

	GetEnteteReglement() {
		this.enteteReglement = '- Aucun';
		const montantTotal = new Number(this.montant);
		let montantRegleTotal = 0;
		this.reglements.forEach(function (element) {
			montantRegleTotal += element.montantRegle;
		}, this);

		if (montantRegleTotal != 0) {
			if (montantTotal > montantRegleTotal) {
				this.enteteReglement = '- En cours : reste à solder : ' + (montantTotal - montantRegleTotal).toFixed(2);
			} else {
				this.enteteReglement = '- Facture soldée : montant règlement remis : ' + montantRegleTotal.toFixed(2);
			}
		}
	}

	getMontantAvoir() {
		if (this.avoir) {
			this.enteteMontantAvoir = '/ Montant avoir : ' + this.avoir.toFixed(2);
		}
	}

	async getFacture() {
		this.facture = await this.FacturesService.getFactureById(this.idFacture);
	}

	async loadReglements() {
		this.startLoading();
		this.newReglement = {};
		this.newReglement.idFacture = this.idFacture;
		if (this.idFacture) {
			try {
				this.reglements = await this.ReglementsService.getFactureReglements(this.idFacture);

				if (this.facture) {
					this.newReglement.idModeReglement = this.facture.idModePaiement;
					const devise = this.devises.find((d) => d.libelle == this.facture.deviseSymbole);
					if (devise) this.newReglement.idDevise = devise.id;
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.GetEnteteReglement();
				this.getMontantAvoir();
				this.showAddLine = false;
				this.stopLoading();
			}
		}
	}

	addLineOn() {
		this.showAddLine = true;
	}

	addLineOff() {
		this.showAddLine = false;
	}

	async deleteReglement(reglement) {
		const that = this;
		if (reglement && reglement.id) {
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('FACTURES.REGLEMENT.DELETE.TITLE'),
				modalMsg: this.$translate.instant('FACTURES.REGLEMENT.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				try {
					await that.ReglementsService.deleteFactureReglementById(reglement.id);
					that.notification.success(that.$translate.instant('FACTURES.REGLEMENT.DELETE.SUCCESS'));
					that.loadReglements();
				} catch (ex) {
					that.notification.error(ex.data);
				}
			});
		}
	}

	activeEditMode(reglement) {
		this.modeReglementBeforeUpdate = reglement.idModeReglement;
		this.referenceBeforeUpdate = reglement.reference;
		this.montantRegleBeforeUpdate = reglement.montantRegle;
		this.deviseBeforeUpdate = reglement.idDevise;
		this.dateReceptionBeforeUpdate = reglement.dateReception;
		this.dateRemiseBeforeUpdate = reglement.dateRemise;
		reglement.isEditMode = true;
	}

	desactiveEditMode(reglement) {
		reglement.idModeReglement = this.modeReglementBeforeUpdate;
		reglement.reference = this.referenceBeforeUpdate;
		reglement.montantRegle = this.montantRegleBeforeUpdate;
		reglement.idDevise = this.deviseBeforeUpdate;
		reglement.dateReception = this.dateReceptionBeforeUpdate;
		reglement.dateRemise = this.dateRemiseBeforeUpdate;
		reglement.isEditMode = false;
	}

	async createReglement(reglement) {
		if (
			reglement &&
			reglement.idModeReglement &&
			reglement.montantRegle &&
			reglement.idDevise &&
			reglement.dateReception &&
			reglement.idFacture
		) {
			if (this.checkDate(reglement)) {
				this.startLoading();
				try {
					await this.ReglementsService.createFactureReglement(reglement);
					this.notification.success(this.$translate.instant('FACTURES.REGLEMENT.CREATE.SUCCESS'));
					this.loadReglements();
				} catch (ex) {
					this.notification.error(ex.data);
				} finally {
					this.stopLoading();
				}
			}
		}
	}

	async updateReglement(reglement) {
		if (reglement && reglement.id && reglement.idModeReglement && reglement.montantRegle && reglement.idDevise && reglement.dateReception) {
			if (this.checkDate(reglement)) {
				this.startLoading();
				try {
					await this.ReglementsService.updateFactureReglement(reglement);
					this.notification.success(this.$translate.instant('FACTURES.REGLEMENT.UPDATE.SUCCESS'));
					this.newReglement = {};
					this.loadReglements();
				} catch (ex) {
					this.notification.error(ex.data);
				} finally {
					this.stopLoading();
				}
			}
		}
	}

	checkDate(reglement) {
		let response = true;
		const dateFacture = this.moment(this.dateFacture, this.dateFormat);
		const dateReception = this.moment(reglement.dateFacture, this.dateFormat);
		let dateRemise = new Date();
		if (reglement.dateRemise) {
			dateRemise = this.moment(reglement.dateRemise, this.dateFormat);
		}
		if (dateFacture > dateReception) {
			this.notification.error(this.$translate.instant('FACTURES.DATE_RECEPTION_SUP_OU_EGAL'));
			response = false;
		}
		if (reglement.dateRemise && dateReception > dateRemise) {
			this.notification.error(this.$translate.instant('FACTURES.DATE_REMISE_SUP_OU_EGAL'));
			response = false;
		}
		return response;
	}

	async getModesPaiement() {
		if (!this.modeReglements) {
			this.startLoading();
			try {
				this.modeReglements = await this.ReglementsService.getModesPaiement();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}

	async getDevises() {
		if (!this.devises) {
			this.startLoading();
			try {
				this.devises = await this.ReglementsService.getDevises();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

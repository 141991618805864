export default class ConfigurationEmailListController {
	static $inject = [
		'$scope',
		'$state',
		'$translate',
		'ModalService',
		'PaginationService',
		'ConfigurationEmailService',
		'notification',
		'$uibModal'
	];

	constructor($scope, $state, $translate, ModalService, PaginationService, ConfigurationEmailService, notification, $uibModal) {
		this.$scope = $scope;
		this.$state = $state;
		this.$translate = $translate;
		this.$uibModal = $uibModal;
		this.ModalService = ModalService;
		this.PaginationService = PaginationService;
		this.ConfigurationEmailService = ConfigurationEmailService;
		this.notification = notification;
		this.loading = false;
		this.societes = [];
		this.domaines = [];
		this.idSociete = null;
		this.configurationSmtp = null;
		this.configurationsEmail = [];
	}

	async $onInit() {
		await this.loadSocietes();
		await this.loadDomaines();

		if (this.societes.length > 0) {
			this.idSociete = this.societes[0].id;
			await this.loadConfigurationsEmailSmtp(this.idSociete);
			if (this.configurationSmtp.id > 0) {
				await this.loadConfigurationsEmail(this.idSociete);
			}
		}
	}

	async loadSocietes() {
		this.startLoading();
		try {
			this.societes = await this.ConfigurationEmailService.getSocietes();
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.stopLoading();
		}
	}

	async loadDomaines() {
		this.startLoading();
		try {
			this.domaines = await this.ConfigurationEmailService.getDomains();
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.stopLoading();
		}
	}

	async loadConfigurationsEmailSmtp(idSociete) {
		this.startLoading();
		try {
			this.configurationSmtp = await this.ConfigurationEmailService.getConfigurationSmtpBySociete(idSociete);
			if (this.configurationSmtp) {
				await this.loadConfigurationsEmail(this.idSociete);
			}
		} catch (error) {
			this.notification.error('CONFIGURATION_EMAIL.ALERT_NO_CONFIGURATION_SMTP');
		}
		this.stopLoading();
	}

	async loadConfigurationsEmail(idSociete) {
		this.startLoading();
		try {
			this.configurationsEmail = await this.ConfigurationEmailService.getConfigurationEmailList(idSociete);
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.stopLoading();
		}
	}

	async getModalConfigureSocieteSmtp(idSociete) {
		const modalInstance = this.ModalService.open({
			component: 'configurationEmailSmtpForm',
			animation: true,
			size: 'l',
			backdrop: false,
			resolve: {
				libelleSociete: () => this.societes.find((x) => x.id === idSociete).libelle,
				idSociete: () => idSociete
			},
			windowClass: 'modern-modal'
		});
		try {
			await modalInstance.result;

			await this.loadConfigurationsEmailSmtp(idSociete);
		} catch (error) {
			console.error('🔥: ConfigurationEmailListController -> getModalConfigureSocieteSmtp -> error', error);
		}
	}

	async getModalConfig(idconfig) {
		const modalInstance = this.ModalService.open({
			component: 'configurationEmailForm',
			animation: true,
			size: 'xl',
			backdrop: false,
			resolve: {
				idconfig: () => idconfig
			},
			windowClass: 'modern-modal'
		});
		modalInstance.result.then(async () => {
			try {
				await this.loadConfigurationsEmail(this.idSociete);
			} catch (error) {
				console.log('🔥: ConfigurationEmailListController -> getModalConfig -> error', error);
			}
		});
	}

	isCustomConfiguration(configuration) {
		return (
			configuration.message !== configuration.messageDefault ||
			configuration.titre !== configuration.titreDefault ||
			configuration.smtpAlias !== configuration.smtpAliasDefault
		);
	}

	async resetConfiguration(idConfig) {
		if (idConfig) {
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('CONFIGURATION_EMAIL.RESET.TITLE'),
				modalMsg: this.$translate.instant('CONFIGURATION_EMAIL.RESET.MESSAGE'),
				headerClass: 'modal-danger'
			});
			modalInstance.result.then(async () => {
				try {
					await this.ConfigurationEmailService.resetConfigurationEmail(idConfig);
					await this.loadConfigurationsEmail(this.idSociete);
					this.notification.success(this.$translate.instant('CONFIGURATION_EMAIL.RESET.SUCCESS'));
				} catch (ex) {
					this.notification.error(this.$translate.instant('CONFIGURATION_EMAIL.RESET.ERROR'));
				}
			});
		}
	}

	async activateConfiguration(idConfig) {
		try {
			await this.ConfigurationEmailService.activateConfigurationEmail(idConfig);
			await this.loadConfigurationsEmail(this.idSociete);
			this.notification.success(this.$translate.instant('CONFIGURATION_EMAIL.ACTIVATE.SUCCESS'));
		} catch (ex) {
			this.notification.error(this.$translate.instant('CONFIGURATION_EMAIL.ACTIVATE.ERROR'));
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

const serviceUrl = `${__configuration.apiUrl}/massia/tvas`;

export default class TvasService {
    static $inject = ['$http'];

    constructor($http, TypesNiveauxHierarchique) {
        this.$http = $http;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
    }

    async getList(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteTvaById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getTvaById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createTva(tva) {
        const data = tva;
        const result = await this.$http.post(serviceUrl, data);

        return result.data;
    }

    async updateTva(tva) {
        const data = tva;
        const url = `${serviceUrl}/${tva.id}`;
        return await this.$http.put(url, data);
    }

    async updateCompteTva(tva) {
        const data = tva;
        const url = `${serviceUrl}/compte`;
        return await this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getTvaHeaders() {
        try {
            const res = await this.$http.get(`${serviceUrl}/headers`);
            return res.data;
        } catch (err) {
            throw err.data;
        }
    }
}

import Vehicule from './vehicule.model';

export default class VehiculeFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'VehiculesCommunicationService',
		'VehiculesService',
		'notification',
		'$location',
		'$anchorScroll',
		'MOPService'
	];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		VehiculesCommunicationService,
		VehiculesService,
		notification,
		$location,
		$anchorScroll,
		MOPService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.VehiculesCommunicationService = VehiculesCommunicationService;
		this.VehiculesService = VehiculesService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.MOPService = MOPService;
	}

	$onInit() {
		this.MOPService.setMop([{ title: 'VEHICULES.GESTION_VEHICULE', filename: 'GestionVehicules.pdf', application: 'gestion' }]);
		this.vehicule = {};
		this.loading = false;
		this.form = {
			entete: {}
		};
		this.reset();
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateVehiculeTimeout);
		this.MOPService.resetMop();
	}

	async reset() {
		this.startLoading();
		const data = { id: this.$stateParams.id };
		if (data.id) {
			try {
				data.entete = await this.VehiculesService.getVehiculeEnteteById(data.id);
			} catch (ex) {
				this.notification.error(ex.data);
				this.annuler();
			}
		}
		this.vehicule = new Vehicule(data);

		this.ongletOpen = {
			isEnteteOpen: true
		};

		this.$timeout.cancel(this.updateVehiculeTimeout);
		this.updateVehiculeTimeout = this.$timeout(() => this.stopLoading());
	}

	async sauvegarder() {
		if (typeof this.vehicule.entete.objetImmatBenne === 'object') {
			//if (typeof this.vehicule.entete.objetImmatTracteur == "object" || typeof this.vehicule.entete.objetImmatTracteur == undefined) {
			if (this.checkValidity()) {
				this.startLoading();

				try {
					let id = false;

					if (this.vehicule.id) {
						await this.VehiculesService.updateEnteteVehicule(this.vehicule.entete);
						this.notification.success('VEHICULES.UPDATED');
						id = this.vehicule.id;
					} else {
						id = await this.VehiculesService.createVehicule(this.vehicule.entete);
						this.notification.success('VEHICULES.CREATED');
					}

					return id;
				} catch (ex) {
					this.notification.error(ex.data);
					return false;
				} finally {
					this.stopLoading();
				}
			}
			// } else if (typeof this.vehicule.entete.objetImmatTracteur != "object") {
			//     this.notification.warning('VEHICULES.TRACTEURNONEXISTANT');
			// }
		} else {
			this.notification.warning('VEHICULES.BENNENONEXISTANTE');
		}
	}

	async appliquer() {
		const id = await this.sauvegarder();
		if (id && this.vehicule.id) {
			this.reset();
		} else if (id) {
			this.$state.go('vehicules.edit', { id: id });
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();

		if (success) {
			this.annuler();
		}
	}

	async annuler() {
		this.$state.go('vehicules.list');
	}

	checkValidity() {
		/*if (this.vehicule.entete.objetImmatBenne && !this.vehicule.entete.objetImmatBenne.id) {
            this.notification.error('VEHICULES.NO_BENNE_AVAILABLE');
            return false
        }
        if (this.vehicule.entete.objetImmatTracteur && !this.vehicule.entete.objetImmatTracteur.id) {
            this.notification.error('VEHICULES.NO_TRACTEUR_AVAILABLE');
            return false
        }
        else {*/
		//this.closeAllOnglets();
		let validity = true;

		let firstScroll = true;
		if (!this.vehicule.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('vehiculeEnteteValidations');
		}

		return validity;
		//}
	}

	onUpdateEntete(entete) {
		this.vehicule.entete = entete;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

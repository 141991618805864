const serviceUrl = `${__configuration.apiUrl}/massia/configuration-email`;

export default class ConfigurationEmailService {
	static $inject = ['$http', 'MassiaApplicationService'];

	constructor($http, MassiaApplicationService) {
		this.$http = $http;
		this.MassiaApplicationService = MassiaApplicationService;
	}

	//SMTP
	async getConfigurationSmtpBySociete(idSociete) {
		let res = await this.$http.get(serviceUrl + '/smtp/societe/' + idSociete);
		return res.data;
	}

	async createConfigurationEmailSmtp(configuration) {
		return await this.$http.post(`${serviceUrl}/smtp/`, configuration);
	}

	async updateConfigurationEmailSmtp(idConfig, configuration) {
		const url = `${serviceUrl}/smtp/${idConfig}`;
		return await this.$http.put(url, configuration);
	}

	//CONFIGURATION EMAIL
	async getConfigurationEmailList(idSociete) {
		let res = await this.$http.get(serviceUrl + '/societe/' + idSociete);
		return res.data;
	}

	async getConfigurationEmailById(idConfig) {
		var siteUrl = `${serviceUrl}/${idConfig}`;
		let res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getConfigurationEmailBySocieteAndDomaine(idSociete, domain) {
		var siteUrl = `${serviceUrl}/${idSociete}/${domain}`;
		let res = await this.$http.get(siteUrl);
		return res.data;
	}

	async createConfigurationEmail(configuration) {
		return await this.$http.post(serviceUrl, configuration);
	}

	async updateConfigurationEmail(idConfig, configuration) {
		const url = `${serviceUrl}/${idConfig}`;
		return await this.$http.put(url, configuration);
	}

	async resetConfigurationEmail(idConfig) {
		const url = `${serviceUrl}/${idConfig}/reset`;
		return this.$http.put(url);
	}

	async activateConfigurationEmail(idConfig) {
		const url = `${serviceUrl}/${idConfig}/activate`;
		console.log('🔥: ConfigurationEmailService -> activateConfigurationEmail -> url', url);
		return this.$http.put(url);
	}

	async getDomaineTypeEnum() {
		const url = 'assets/enum/email-domaine-type/EmailDomaineType.json';

		const res = await this.$http.get(url);
		return res.data;
	}

	async getConfigurationsEmailBalises(idDomaine, domainType) {
		var url = `${serviceUrl}/balises/${idDomaine}/${domainType}`;
		let res = await this.$http.get(url);
		return res.data || [];
	}

	async getSocietes() {
		var siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales`;
		let res = await this.$http.get(siteUrl);

		return res.data || [];
	}

	async getDomains() {
		var url = `${__configuration.apiUrl}/massia/domaines`;
		let res = await this.$http.get(url, {
			params: {
				appName: this.MassiaApplicationService.getApplication()
			}
		});
		return res.data || [];
	}
}

configurationEmailFormValidator.$inject = ['validator'];

export default function configurationEmailFormValidator(validator) {
	const instance = new validator();

	instance.ruleFor('idSociete').notEmpty().when(isRequired).withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('idDomaine').notEmpty().when(isRequired).withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('titre').length(0, 500).when(isCompleted).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
	instance.ruleFor('message').length(0, 500).when(isCompleted).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
	instance.ruleFor('smtpAlias').emailAddress().when(isCompleted).withMessage('VALIDATION_EMAIL');
	return instance;
	function isCompleted(obj, prop) {
		return prop != null && typeof prop != 'undefined' && prop != '';
	}

	function isRequired(obj) {
		if (obj.id == null) {
			return true;
		}
	}
}

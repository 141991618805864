const serviceUrl = `${__configuration.apiUrl}/massia/commandes`;

export default class CommandesService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getCommandes(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getSiteById(id) {
		const genre = 1; // SL pour ne pas faire le traitement de recuperation des EtatCompteOuvert
		const application = 'gestion';
		const url = `${__configuration.apiUrl}/massia/sites/${id}/entete/genre/${genre}/${application}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async deleteCommandeById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getCommandeEnteteById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async createCommande(commande) {
		const result = await this.$http.post(serviceUrl, commande);

		return result.data;
	}

	async duplicateCommande(id) {
		const url = `${__configuration.apiUrl}/massia/commandes/${id}/duplicate`;
		const result = await this.$http.post(url);
		return result.data;
	}

	async updateCommande(commande) {
		const url = `${serviceUrl}/${commande.entete.id}`;
		return this.$http.put(url, commande.entete);
	}

	async transformFacture(idCommande, date, idModePaiement) {
		const data = {
			idCommande: idCommande,
			dateFacture: date,
			idModePaiement: idModePaiement
		};
		const url = `${__configuration.apiUrl}/massia/commandes/transform-facture`;
		const result = await this.$http.post(url, data);
		return result.data;
	}

	async getModesPaiement() {
		const serviceUrl = `${__configuration.apiUrl}/massia/paiements/headers`;
		const res = await this.$http.get(serviceUrl);
		return res.data;
	}

	async getPrestations() {
		const url = `${__configuration.apiUrl}/massia/prestations`;
		const res = await this.$http.get(url);

		return res.data.items;
	}

	async getTransports() {
		const url = `${__configuration.apiUrl}/massia/transports`;
		const res = await this.$http.get(url);

		return res.data.items;
	}

	async getChantierById(id) {
		const url = `${__configuration.apiUrl}/massia/chantiers/${id}/entete`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTarifPrestation(chantierId, siteCommercialId, prestationId) {
		const uniteUrl = `${__configuration.apiUrl}/massia/grillesTarifaires/siteCommercial/${siteCommercialId}/prixPrestation/${prestationId}?filters={"achatVente": true, "chantierId": ${chantierId}}`;
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}

	async getTarifTransport(chantierId, siteCommercialId, transportId, benneId, zoneId) {
		const uniteUrl = `${__configuration.apiUrl}/massia/grillesTarifaires/siteCommercial/${siteCommercialId}/prixTransport/${transportId}/${benneId}/${zoneId}?filters={"achatVente": true, "chantierId": ${chantierId}}`;
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}

	async getDistancier(isChantier, lieuDeparts, lieuArrivee) {
		const url = `${__configuration.apiUrl}/massia/distanciers`;

		const filtres = {
			isChantier: isChantier,
			lieuDepartIds: lieuDeparts,
			lieuArriveeIds: lieuArrivee
		};
		const res = await this.$http.get(url, {
			params: {
				filtres: JSON.stringify(filtres || {})
			}
		});

		return res.data;
	}

	async getProduits() {
		const uniteUrl = `${__configuration.apiUrl}/massia/produits`;
		const res = await this.$http.get(uniteUrl);

		return res.data.items;
	}

	async getSitesProducteurs() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs`;
		const res = await this.$http.get(siteUrl);
		return res.data.items;
	}

	async getSitesProducteursCombo(idSociete) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs/header/${idSociete}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getSitesCommerciaux() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux`;
		const res = await this.$http.get(siteUrl);
		return res.data.items;
	}

	async getSitesCommerciauxCombo(idSociete) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getBennes() {
		const siteUrl = `${__configuration.apiUrl}/massia/gestion/type-bennes/headers`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getSocietes() {
		const siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getClientsCombo(idSociete, valeur) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-clients/typeAheadCompteOuvert/${idSociete}/${valeur}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getCommandeTypes() {
		const url = `${__configuration.apiUrl}/massia/domaines/Commandes/types`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getChantiersCombo(idSite) {
		const dateFacture = null;
		const siteUrl = `${__configuration.apiUrl}/massia/chantiers/header/${idSite}/${dateFacture}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getLieux(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getDevises() {
		const url = `${__configuration.apiUrl}/massia/devises`;
		const res = await this.$http.get(url);
		return res.data.items;
	}

	async getTarifs(siteCommercialId, chantierId, date) {
		const monFiltre = JSON.stringify({ chantierId: chantierId, achatVente: true, date: date });
		const url = `${__configuration.apiUrl}/massia/grillesTarifaires/siteCommercial/${siteCommercialId}/prix?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async estCodeAuto() {
		const url = `${serviceUrl}/est-code-auto`;
		try {
			const res = await this.$http.get(url);
			return res.data;
		} catch (err) {
			if (err.status === 404) {
				return false;
			}
			throw err;
		}
	}

	async getPaysById(id) {
		const url = `${__configuration.apiUrl}/massia/pays/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocieteById(id) {
		const application = 'gestion';
		const url = `${__configuration.apiUrl}/massia/societes/${id}/entete/${application}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportListeCommandes(modeImpr, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				modeImpr: JSON.stringify(modeImpr),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getTvaList() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/tvas/headers`);
		return res.data;
	}

	async getProduitTva(filters) {
		const monFiltre = JSON.stringify(filters || []);
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/parametres/tvaProduits/valeurs?filters=` + monFiltre);
		return res.data;
	}

	// SL ******************** le service existe déjà pour les factures manu ****************************
	async getProduitsSelectionnables(filtres) {
		const monFiltre = JSON.stringify(filtres);
		const url = `${__configuration.apiUrl}/massia/factures/produitsFactureManu?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getZoneForTransport(idSiteCom, idChantier, idLieu, idGrilleZoneKm) {
		const input = {};
		input.idSiteCom = idSiteCom;
		input.idChantier = idChantier;
		input.idLieu = idLieu;
		input.idGrilleZoneKm = idGrilleZoneKm;
		const myInput = JSON.stringify(input);
		const url = `${__configuration.apiUrl}/massia/distanciers/getZoneCommande?inputs=${myInput}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async actualizeQuantiteLivrees(id) {
		const url = `${serviceUrl}/actualize/${id}`;
		return this.$http.put(url);
	}

	async updateTgapExclure(id, type) {
		const url = `${__configuration.apiUrl}/massia/gestion/tgap-exclure/${id}/${type}`;
		return await this.$http.put(url);
	}

	async getCommandeComposant(id, domaine) {
		const url = `${__configuration.apiUrl}/massia/commande-composant/${id}/${domaine}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportCommandeComposants(id, domaine) {
		const url = `${__configuration.apiUrl}/massia/commande-composant/print/${id}/${domaine}`;
		const res = await this.$http.get(url, {
			params: {},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async printCommande(id, data) {
		const url = `${serviceUrl}/${id}/print`;
		const res = await this.$http.post(url, data);
		return res.data;
	}
}

import FacturesCheckpont from './factures.checkpont.model';

export default class FacturesCheckpontController {
	static $inject = ['$scope', '$state', '$stateParams', 'ModalService', 'notification', '$translate', '$filter', '_', '$uibModal', 'TvaService'];

	constructor($scope, $state, $stateParams, ModalService, notification, $translate, $filter, _, $uibModal, TvaService) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this.$uibModal = $uibModal;
		this.TvaService = TvaService;
	}

	$onInit() {
		this.startLoading();

		this.hasBeenModified = false;
		this.cdgOpened = false;

		this.stopLoading();
	}

	open(domaine, article) {
		this.cdgOpened = true;
		const _this = this;

		const source = {};
		if (domaine == 'SiteClient') {
			source.id = article.id;
			source.entete = {};
			source.entete.id = article.id;
			source.entete.libelle = article.libelle;
			source.entete.nomType = domaine;
		}
		else {
			source.id = article.idArticle;
			source.entete = {};
			source.entete.id = article.idArticle;
			source.entete.libelle = article.nomArticle;
			source.entete.nomType = domaine;
			source.entete.type = article.typeArticleEnum;
	
			source.filtresAppliques = false;
	
			if (article.typeArticle != 0) {
				source.type = article.typeArticleEnum;
			} else {
				source.entete.typeLibelle = article.nomTypeProduit;
				source.entete.typeId = article.idTypeProduit;
			}
		}

		this.$uibModal
			.open({
				template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = source;
						$ctrl.domaine = domaine;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				async function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
					if (result) {
						_this.hasBeenModified = result;
						_this.cdgOpened = false;
					}
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	activeEditMode(tva) {
		this.compteDebitBeforeUpdate = tva.compteDebit;
		this.compteEncaissementBeforeUpdate = tva.compteEncaissement;
		tva.isEditMode = true;
	}

	desactiveEditMode(tva) {
 		tva.compteDebit = this.compteDebitBeforeUpdate;
		tva.compteEncaissement = this.compteEncaissementBeforeUpdate;
		tva.isEditMode = false;
	}

	async updateTva(tva) {
        this.startLoading();
        try {
            await this.TvaService.updateCompteTva(tva);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
			tva.isEditMode = false;
            this.stopLoading();
        }
    }

	cancel() {
		this.modalInstance.dismiss();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

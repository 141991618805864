configurationEmailSmtpFormValidator.$inject = ['validator'];

export default function configurationEmailSmtpFormValidator(validator) {
	const instance = new validator();

	instance.ruleFor('idSociete').notEmpty().when(isRequired).withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('smtpLogin').emailAddress().withMessage('VALIDATION_EMAIL');
	instance.ruleFor('smtpPass').length(1, 50).withMessage('VALIDATION_TOO_LONG_50');
	instance.ruleFor('smtpServer').length(1, 50).withMessage('VALIDATION_TOO_LONG_50');
	instance.ruleFor('smtpPort').greaterThan(0).withMessage('VALIDATION_NOT_INTEGER_POSITIVE');
	instance.ruleFor('smtpAlias').emailAddress().when(isCompleted).withMessage('VALIDATION_EMAIL');
	return instance;
	function isCompleted(obj, prop) {
		return prop != null && typeof prop != 'undefined' && prop != '';
	}

	function isRequired(obj) {
		if (obj.id == null) {
			return true;
		}
	}
}

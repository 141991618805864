import validatorBuilder from 'validator-builder';

let configurationEmailFormValidator = null;

export default class ConfigurationEmailForm {
	constructor(data) {
		data = data || {};

		this.id = data.id || null;

		this.idSociete = data.idSociete || null;
		this.libelleSociete = data.libelleSociete || null;
		this.idDomaine = data.idDomaine || null;
		this.codeDomaine = data.codeDomaine || null;
		this.libelleDomaine = data.libelleDomaine || null;
		this.domaineType = data.domaineType || null;
		this.titre = data.titre || null;
		this.message = data.message || null;
		this.titreDefault = data.titreDefault || null;
		this.messageDefault = data.messageDefault || null;
		this.smtpAlias = data.smtpAlias || null;
		this.smtpAliasDefault = data.smtpAliasDefault || null;
		this.isForPortail = data.isForPortail || false;
		this.domainePortailType = data.domainePortailType || null;
		this.idDomainePortail = data.idDomainePortail || null;
	}

	isValid() {
		configurationEmailFormValidator = configurationEmailFormValidator || validatorBuilder.getInstanceFor('ConfigurationEmailForm');

		const validationResults = configurationEmailFormValidator.validate(this);

		return validationResults.isValid;
	}
}

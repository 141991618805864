import ConfigurationEmailSmtpForm from './configuration.email.smtp.form.model';
export default class ConfigurationEmailSmtpFormController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'ModalService',
		'ConfigurationEmailService',
		'notification',
		'$translate',
		'$filter',
		'_',
		'$uibModal'
	];
	constructor($scope, $state, $stateParams, ModalService, ConfigurationEmailService, notification, $translate, $filter, _, $uibModal) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ModalService = ModalService;
		this.ConfigurationEmailService = ConfigurationEmailService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this._ = _;
		this.$uibModal = $uibModal;
		this.configurationSmtp = new ConfigurationEmailSmtpForm();
		this.loading = false;
		this.isEdit = false;
	}

	async $onInit() {
		console.log('🔥: ConfigurationEmailSmtpFormController -> $onInit -> this.resolve', this.resolve);
		if (this.resolve.idSociete > 0) {
			await this.loadConfigurationSmtp(this.resolve.idSociete);
			if (this.configurationSmtp.id > 0) {
				this.isEdit = true;
			}
		}
	}

	async loadConfigurationSmtp(idSociete) {
		this.startLoading();
		try {
			const configSmtp = await this.ConfigurationEmailService.getConfigurationSmtpBySociete(idSociete);
			if (configSmtp) {
				this.configurationSmtp = new ConfigurationEmailSmtpForm(configSmtp);
				console.log('🔥: ConfigurationEmailSmtpFormController -> loadConfigurationSmtp -> this.configurationSmtp', this.configurationSmtp);
			}
		} catch (error) {
			this.notification.error('CONFIGURATION_EMAIL.FORM.CONFIGURATION_NOT_FOUND');
		}
		this.stopLoading();
	}

	async save() {
		if (this.checkValidity()) {
			const form = {
				idSociete: this.configurationSmtp.idSociete,
				smtpLogin: this.configurationSmtp.smtpLogin,
				smtpPass: this.configurationSmtp.smtpPass,
				smtpServer: this.configurationSmtp.smtpServer,
				smtpPort: this.configurationSmtp.smtpPort,
				smtpAlias: this.configurationSmtp.smtpAlias,
				smtpIsSslConnection: this.configurationSmtp.smtpIsSslConnection
			};

			if (this.isEdit) {
				await this.ConfigurationEmailService.updateConfigurationEmailSmtp(this.configurationSmtp.id, form);
			} else {
				await this.ConfigurationEmailService.createConfigurationEmailSmtp(form);
			}
			this.notification.success('CONFIGURATION_EMAIL.FORM.CONFIGURATION_SAVED');
			this.ok();
		} else {
			return this.notification.error('CONFIGURATION_EMAIL.FORM.VALIDATION_ERROR');
		}
	}

	ok() {
		this.close({
			$value: this.configurationSmtp.id
		});
	}

	cancel() {
		this.dismiss();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	checkValidity() {
		let validity = true;
		if (!this.configurationSmtp.isValid()) {
			validity = false;
		}
		return validity;
	}
}

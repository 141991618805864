import Societe from './societe.model';

export default class SocieteFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'SocietesCommunicationService',
		'SocietesService',
		'notification',
		'$location',
		'$anchorScroll',
		'_',
		'MassiaApplicationService',
		'$uibModal',
		'MOPService'
	];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		SocietesCommunicationService,
		SocietesService,
		notification,
		$location,
		$anchorScroll,
		_,
		MassiaApplicationService,
		$uibModal,
		MOPService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SocietesCommunicationService = SocietesCommunicationService;
		this.SocietesService = SocietesService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this._ = _;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$uibModal = $uibModal;
		this.ongletOpen = {
			isEnteteOpen: false,
			isCaracteristiqueOpen: false,
			isNiveauxHierarchiqueOpen: false,
			isArgumentOpen: false,
			coordoneesBancairesOpened: false,
			pontComptableOpened: true,
			societesAssocieesOpened: false,
			parametreTransportOpened: false
		};
		this.ongletOpenVal = {
			isEnteteOpen: 'entete',
			isCaracteristiqueOpen: 'caracteristiques',
			isNiveauxHierarchiqueOpen: 'niveaux',
			coordoneesBancairesOpened: 'coordonneesBancaires',
			pontComptableOpenend: 'pontComptable',
			societesAssocieesOpened: 'societesAssociees',
			parametreTransportOpened: 'parametreTransport'
		};
		this.MOPService = MOPService;
	}

	$onInit() {
		this.MOPService.setMop([
			{ title: 'SOCIETES.BREADCRUMBS.SOCIETES_LIST', filename: 'Societes.pdf', application: 'gestion' },
			{ title: 'SOCIETES.FILTRES_SOC_SITES', filename: 'FiltresSocietesSites.pdf', application: 'gestion' },
			{ title: 'FACTURES.BREADCRUMBS.FACTURES_PONT', filename: 'PontComptable.pdf', application: 'gestion' }
		]);
		this.societe = {};
		this.loading = false;
		this.form = {
			entete: {},
			caracteristiques: {},
			niveaux: {},
			coordoneesBancaires: {},
			pontComptable: {},
			societesAssociees: {},
			parametreTransport: {}
		};
		if (!this.modalInstance) {
			this.genreSociete = this.$stateParams.genre;
		} else {
			this.genreSociete = this.genreSociete.toString();
		}
		this.reset();
	}

	open(domaine) {
		const _this = this;
		_this.source = this.societe;
		_this.domaine = domaine;
		this.$uibModal
			.open({
				template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = _this.domaine;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function () {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function () {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	openTarifs() {
		const _this = this;
		_this.source = this.societe;
		//_this.domaine = domaine;
		this.$uibModal
			.open({
				template: '<tarifs source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></tarifs>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = 'societe';
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function () {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function () {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	openGrilles() {
		const _this = this;
		_this.source = this.societe;
		//_this.domaine = domaine;
		this.$uibModal
			.open({
				template: '<grilles source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></grilles>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = 'societe';
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function () {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function () {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateSocieteTimeout);
		this.MOPService.resetMop();
	}

	async reset() {
		this.startLoading();

		const data = {
			id: !this.modalInstance ? this.$stateParams.id : undefined
		};

		if (data.id) {
			try {
				data.entete = await this.SocietesService.getSocieteEnteteById(data.id, this.MassiaApplicationService.getApplication());
				data.caracteristiques = await this.SocietesService.getSocieteValeursCaracteristiquesAssociationsById(data.id);
				data.niveaux = await this.SocietesService.getSocieteValeursNiveauxById(data.id);
				data.argumentSocietes = await this.SocietesService.getArgumentsById(data.id);
				data.coordonneesBancaires = await this.SocietesService.getCoordonnees(data.id);
				data.societesAssociees = await this.SocietesService.getSocietesAssociees(data.id);
				// data.parametreTransport = await this.SocietesService.getParametreTransport(data.id);
				data.pontComptable = data.entete.pontComptable;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
				this.annuler();
			}
			this.societe = new Societe(data);
		} else {
			this.societe = new Societe(data);
			switch (this.genreSociete) {
				case '1':
					this.societe.entete.isUtilisateur = true;
					break;
				case '2':
					this.societe.entete.isClient = true;
					break;
				case '4':
					this.societe.entete.isProducteur = true;
					break;
				case '16':
					this.societe.entete.isTransporteur = true;
					break;
				case '8':
					this.societe.entete.isFournisseur = true;
					break;
				case '32':
					this.societe.entete.isLaboratoire = true;
					break;
				case '64':
					this.societe.entete.isProprietaire = true;
					break;
				case '128':
					this.site.entete.isLocataire = true;
					break;
				case '256':
					this.societe.entete.isAuditeur = true;
					break;
				default:
					break;
			}
		}

		if (this.$stateParams.duplicate) {
			this.societe.id = undefined;
			this.societe.entete.code += '_copie';
		}
		this.ongletOpen = {
			isEnteteOpen: true,
			isCaracteristiqueOpen: false,
			isNiveauxHierarchiqueOpen: false,
			isArgumentOpen: false,
			coordoneesBancairesOpened: false,
			pontComptableOpened: false,
			societesAssocieesOpened: false,
			parametreTransportOpened: false
		};

		this.arguments = await this.refreshArguments();
		this.preselectArguments();

		this.$timeout.cancel(this.updateSocieteTimeout);
		this.updateSocieteTimeout = this.$timeout(() => this.stopLoading());
	}

	async sauvegarder() {
		this.setSelectedArguments();
		if (this.checkValidity()) {
			this.startLoading();
			const genre =
				this.societe.entete.isUtilisateur ||
				this.societe.entete.isClient ||
				this.societe.entete.isProducteur ||
				this.societe.entete.isFournisseur ||
				this.societe.entete.isTransporteur ||
				this.societe.entete.isLaboratoire ||
				this.societe.entete.isProprietaire ||
				this.societe.entete.isLocataire ||
				this.societe.entete.isAuditeur;
			if (genre == undefined || genre == false) {
				this.notification.error('SOCIETES.GENRE_OBLIGATOIRE');
				this.stopLoading();
			} else {
				try {
					let id = false;
					if (this.societe.id) {
						await this.SocietesService.updateSociete(this.societe);
						this.notification.success('SOCIETES.UPDATED');
						id = this.societe.id;
					} else {
						id = await this.SocietesService.createSociete(this.societe, this.MassiaApplicationService.getApplication());
						this.notification.success('SOCIETES.CREATED');
					}

					return id;
				} catch (ex) {
					this.notification.error(ex.data);
					return false;
				} finally {
					this.stopLoading();
				}
			}
		}
	}

	async appliquer() {
		const id = await this.sauvegarder();

		if (id && this.societe.id) {
			this.reset();
		} else if (id) {
			if (this.modalInstance) {
				this.modalInstance.dismiss();
			} else {
				this.$state.go(this.getUrlEdit(), { id: id, genre: this.genreSociete });
			}
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();

		if (success) {
			this.annuler();
		}
	}

	annuler() {
		if (this.modalInstance) {
			this.modalInstance.dismiss();
		} else {
			this.$state.go(this.getUrlListe(), { genre: this.genreSociete });
		}
	}

	filtrer() {
		this.$state.go('donnee-filtrees.list', { id: this.societe.id, type: 1 });
	}

	checkValidity() {
		let validity = true;

		let firstScroll = true;
		if (!this.societe.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('societeEnteteValidations');
		}
		if (!this.societe.caracteristiques.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiques');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueOpen = true;
			validity = false;
			this.$scope.$broadcast('societeCaracteristiquesValidations');
		}
		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	onUpdateEntete(entete) {
		this.societe.entete = entete;
	}

	onUpdateCaracteristiques(caracteristiques) {
		this.societe.caracteristiques = caracteristiques;
	}

	onUpdateNiveaux(niveaux) {
		this.societe.niveaux = niveaux;
	}
	onUpdateCoordonnees(coordonnees) {
		this.societe.coordonnees = coordonnees;
	}

	onUpdateSocietesAssociees(societesAssociees) {
		this.societe.societesAssociees = societesAssociees;
	}

	onUpdateParametreTransport(parametreTransport) {
		this.societe.parametreTransport = parametreTransport;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	deleteArgument(argument) {
		const index = this.societe.argumentSocietes.indexOf(argument.id);
		if (index >= 0) {
			this.societe.argumentSocietes.splice(index, 1);
			argument.selected = false;
		}
	}

	selectArgument(argument) {
		const index = this.societe.argumentSocietes.indexOf(argument.id);
		if (index < 0) {
			this.societe.argumentSocietes.push(argument.id);
		}
	}

	async refreshArguments() {
		let data;
		try {
			data = await this.SocietesService.getArguments();
		} catch (ex) {
			data = [];
		}
		return data;
	}

	setSelectedArguments() {
		this._.forEach(this.arguments, (argument) => {
			if (argument.selected) {
				this.selectArgument(argument);
			} else {
				this.deleteArgument(argument);
			}
		});
	}

	preselectArguments() {
		this._.forEach(this.societe.argumentSocietes, (id) => {
			const argument = this._.find(this.arguments, { id: id });
			argument.selected = true;
		});
	}

	getUrlListe() {
		switch (this.genreSociete) {
			case '1':
				return 'societes.list';
			case '2':
				return 'societes-client.list';
			case '4':
				return 'societes-producteur.list';
			case '8':
				return 'societes-fournisseur.list';
			case '16':
				return 'societes-transporteur.list';
			case '32':
				return 'societes-laboratoire.list';
			case '64':
				return 'societes-proprietaire.list';
			case '128':
				return 'societes-locataire.list';
			case '256':
				return 'societes-auditeur.list';
			default:
				return 'societes.list';
		}
	}

	getUrlEdit() {
		switch (this.genreSociete) {
			case '1':
				return 'societes.edit';
			case '2':
				return 'societes-client.edit';
			case '4':
				return 'societes-producteur.edit';
			case '8':
				return 'societes-fournisseur.edit';
			case '16':
				return 'societes-transporteur.edit';
			case '32':
				return 'societes-laboratoire.edit';
			case '64':
				return 'societes-proprietaire.edit';
			case '128':
				return 'societes-locataire.edit';
			case '256':
				return 'societes-auditeur.edit';
			default:
				return 'societes.edit';
		}
	}
}

LieuxController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'LieuxService',
	'notification',
	'MassiaApplicationService',
	'MOPService',
	'$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function LieuxController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	LieuxService,
	notification,
	MassiaApplicationService,
	MOPService,
	$transitions
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	vm.selectedLieuId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.MOPService = MOPService;
	vm.lieux = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectLieu = selectLieu;
	vm.isLieuSelected = isLieuSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadLieux = loadLieux;
	vm.deleteLieu = deleteLieu;
	vm.setSelected = setSelected;
	vm.getApplication = getApplication;
	vm.exporter = exporter;

	function init() {
		vm.MOPService.setMop([{ title: 'LIEUX', filename: 'Lieux.pdf', application: 'gestion' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "lieux.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		//$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		//quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
		if (MassiaApplicationService.getApplication() == 'gestion') {
			vm.adresseOrigine = 'lieux';
		} else {
			vm.adresseOrigine = 'lieux-donnee';
		}
	}

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	init();

	function stateChangeSuccessFunc() {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(lieu) {
		if (lieu && lieu.id) {
			vm.selectedLieuId = $stateParams.id;
			vm.lastSelected = vm.selectedLieuId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedLieuId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state &&
			$state.current &&
			$state.current.name &&
			$state.current.name === vm.adresseOrigine + '.detail' &&
			$stateParams &&
			$stateParams.id
		);
	}

	function selectLieu(lieu) {
		if (lieu && lieu.id) {
			//si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedLieuId, et charger la vue détail pour le lieu sélectionné
			if (vm.selectedLieuId != lieu.id) {
				vm.selectedLieuId = lieu.id;
				showDetail(lieu.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				vm.selectedLieuId = undefined;
				closeDetail();
			}
		}
	}

	function isLieuSelected(lieu) {
		return lieu && lieu.id && vm.selectedLieuId === lieu.id;
	}

	function showDetail(lieuId) {
		$state.go(vm.adresseOrigine + '.detail', { id: lieuId });
	}

	function closeDetail() {
		$state.go(vm.adresseOrigine + '.list');
	}

	async function loadLieux(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "lieux.list", et on ferme la vue détail d'un lieu
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== vm.adresseOrigine + '.list') {
			$state.go(vm.adresseOrigine + '.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.lieux = [];

		try {
			const data = await LieuxService.getLieux(filters, sorts, pagination);

			vm.lieux = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteLieu(lieu) {
		if (lieu && lieu.canDelete && lieu.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('LIEUX.DELETE.TITLE', { code: lieu.libelle }),
				modalMsg: $translate.instant('LIEUX.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await LieuxService.deleteLieuById(lieu.id);
					notification.success($translate.instant('LIEUX.DELETE.SUCCESS'));

					vm.selectedLieuId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						vm.state.go(vm.adresseOrigine + '.list');
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					loadLieux();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_lieux';
			let resultat = undefined;
			try {
				resultat = await LieuxService.exportListeLieux(modeImpression, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('LIEUX.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
					//console.log(ex);
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	function getApplication() {
		return MassiaApplicationService.getApplication();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}

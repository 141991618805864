export default class DomainPortailService {
	constructor($http) {
		this.$http = $http;
		this.url = `${__configuration.apiUrl}/portail/domain`;
	}

	getAll(filters = [], sorts = [], pagination = { skip: 0, take: 0 }) {
		return this.$http.get(this.url, {
			params: {
				filters: JSON.stringify(filters),
				sorts: JSON.stringify(sorts),
				pagination: JSON.stringify(pagination),
				active: true
			}
		});
	}
}

DomainPortailService.$inject = ['$http'];

export default class SettingDisplayController {
	primary;
	accent;
	constructor($scope, SettingPortailService, $translate, notification) {
		this.$scope = $scope;
		this.SettingPortailService = SettingPortailService;
		this.$translate = $translate;
		this.notification = notification;
	}

	async $onInit() {
		await this.SettingPortailService.getDisplaySetting();
		const settings = this.SettingPortailService.displaySetting;
		this.display = this.SettingPortailService.displaySetting;
		this.themes = (await this.SettingPortailService.getThemes()).data;
		if (this.themes && settings?.theme?.id) {
			const theme = this.themes.find((x) => x.id == settings.theme.id);
			this.theme = theme;
			theme.selected = true;
		} else {
			const themeDefault = this.themes.find((x) => x.libelle == 'default-theme');
			themeDefault.selected = true;
			this.theme = themeDefault;
			this.SettingPortailService.displaySetting.theme = themeDefault;
		}
		this.primary = `#${this.theme?.colorPrimary}`;
		this.accent = `#${this.theme?.colorAccent}`;

		this.images = {
			logo: settings.logo ? this.SettingPortailService.setImageUrl(settings.logo) : null,
			logoComplet: settings.logo ? this.SettingPortailService.setImageUrl(settings.logo) : null,
			loginBackground: settings.loginBackground
				? this.SettingPortailService.setImageUrl(settings.loginBackground)
				: 'assets/img/portail/portail_default_login_bg.jpg'
		};
		this.modales = {
			login: false,
			home: false
		};
	}

	selectTheme(data) {
		this.theme = data.selected ? data : null;
		let idxOld =
			this.SettingPortailService.displaySetting.theme != null
				? this.SettingPortailService.themes.findIndex((x) => x.id == this.SettingPortailService.displaySetting.theme.id)
				: -1;

		this.SettingPortailService.displaySetting.theme = this.theme;
		if (idxOld > -1) {
			this.SettingPortailService.themes[idxOld].selected = false;
		}
		if (this.theme) {
			const idx = this.SettingPortailService.themes.findIndex((x) => x.id == this.theme.id);
			this.SettingPortailService.themes[idx].selected = true;
		}
		this.primary = `#${this.theme?.colorPrimary}`;
		this.accent = `#${this.theme?.colorAccent}`;
	}

	$onDestroy() {}

	async onFileChange($files, type, property) {
		console.log($files);
		if ($files.length > 0) {
			await this.SettingPortailService.uploadImage($files, type).then((res) => {
				this.notification.success(this.$translate.instant('IMAGES.IMAGE_CHARGEE_SUCESS'));
				this.SettingPortailService.displaySetting[property] = res.data;
				this.images[property] = this.SettingPortailService.setImageUrl(res.data);
			});
		}
	}
}

SettingDisplayController.$inject = ['$scope', 'SettingPortailService', '$translate', 'notification'];

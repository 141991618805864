import validatorBuilder from 'validator-builder';

let siteEnteteValidator = null;

export default class SiteEntete {
	constructor(data) {
		data = data || {};
		this.id = data.id;
		this.code = data.code;
		this.libelle = data.libelle;
		this.paysId = data.paysId;
		this.lieuId = data.lieuId;
		this.lieuCode = data.lieuCode;
		this.lieu = data.lieu;
		this.typeUtilisateurId = data.typeUtilisateurId;
		this.typeClientId = data.typeClientId;
		this.typeClient = data.typeClient;
		this.typeFournisseurId = data.typeFournisseurId;
		this.typeProducteurId = data.typeProducteurId;
		this.typeTransporteurId = data.typeTransporteurId;
		this.typeLaboratoireId = data.typeLaboratoireId;
		this.typeProprietaireId = data.typeProprietaireId;
		this.typeLocataireId = data.typeLocataireId;
		this.typeAuditeurId = data.typeAuditeurId;
		this.isClient = data.isClient;
		this.isFournisseur = data.isFournisseur;
		this.isProducteur = data.isProducteur;
		this.isTransporteur = data.isTransporteur;
		this.isUtilisateur = data.isUtilisateur;
		this.isLaboratoire = data.isLaboratoire;
		this.isProprietaire = data.isProprietaire;
		this.isLocataire = data.isLocataire;
		this.isAuditeur = data.isAuditeur;
		this.isGenerique = data.isGenerique;
		this.isBatiment = data.isBatiment;
		this.isAQP = data.isAQP;
		this.adresse = data.adresse;
		this.codePostal = data.codePostal;
		this.ville = data.ville;
		this.adresseFacturation = data.adresseFacturation;
		this.codePostalFacturation = data.codePostalFacturation;
		this.villeFacturation = data.villeFacturation;
		this.commentaireClient = data.commentaireClient;
		this.commentaireFacture = data.commentaireFacture;
		this.telephone = data.telephone;
		this.fax = data.fax;
		this.raisonSociale = data.raisonSociale;
		this.mail = data.mail;
		this.website = data.website;
		this.siret = data.siret;
		this.societeId = data.societeId;
		this.tvaIntracommunautaire = data.tvaIntracommunautaire;
		this.codeExists = data.codeExists || null;
		this.etatCompteSites = data.etatCompteSites || [];
		this.deuxTirsParJour = data.deuxTirsParJour;
		this.optimumEnergieElec = data.optimumEnergieElec;
		this.personneId = data.personneId || null;
		this.personne = data.personne || null;
		this.fromWhatName = data.fromWhatName || null;
		this.fromWhatId = data.fromWhatId || null;
		this.naf = data.naf;
		this.forcePasTransportFacturer = data.forcePasTransportFacturer;
		this.niveauVisibiliteAuditeur = 0;
		this.niveauVisibiliteClient = 0;
		this.niveauVisibiliteCommercial = 0;
		this.niveauVisibiliteFournisseur = 0;
		this.niveauVisibiliteLaboratoire = 0;
		this.niveauVisibiliteLocataire = 0;
		this.niveauVisibiliteProducteur = 0;
		this.niveauVisibiliteProprietaire = 0;
		this.niveauVisibiliteTransporteur = 0;
		this.numRecipice = data.numRecipice;
		this.idContacts = data.idContacts;
		this.imageId = data.imageId;
		this.codeApe = data.codeApe;
		this.sousTypeFournisseur = data.sousTypeFournisseur || null;
		this.appName = null;
		this.familles = data.familles || [];
		this.siteProduct = data.siteProduct || [];
		this.siteClientProduct = data.siteClientProduct || [];
		this.ctrlFamilleSurface = data.ctrlFamilleSurface;
		this.regleDateEssai = data.regleDateEssai;
		this.enableSubstitutionPersonnel = data.enableSubstitutionPersonnel;
		this.tokenCerbere = data.tokenCerbere;
		this.syderep = data.syderep;
		this.isTranspByClient = data.isTranspByClient;
	}

	isValid() {
		siteEnteteValidator = siteEnteteValidator || validatorBuilder.getInstanceFor('SiteEntete');
		const validationResults = siteEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}

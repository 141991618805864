/* @ngInject */
export default function MotifsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	MotifsService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedMotifId = undefined;

	vm.loading = false;
	vm.stateParams = $stateParams;
	vm.motifs = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.isMotifSelected = isMotifSelected;
	vm.loadMotifs = loadMotifs;
	vm.deleteMotif = deleteMotif;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([{ title: 'MOTIFS.BREADCRUMBS.MOTIFS_LIST', filename: 'MotifsAnnulation.pdf', application: 'gestion' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "motifs.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		//$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
	}

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	init();

	function stateChangeSuccessFunc() {
		if (!$stateParams.id) {
			selectedMotifId = undefined;
		}
	}

	function isMotifSelected(motif) {
		return motif && motif.id && selectedMotifId === motif.id;
	}

	async function loadMotifs(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "motifs.list", et on ferme la vue détail d'un motif
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'motifs.list') {
			$state.go('motifs.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.motifs = [];

		try {
			const data = await MotifsService.getMotifs(filters, sorts, pagination);
			vm.motifs = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteMotif(motif) {
		if (motif && motif.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('MOTIFS.DELETE.TITLE', { libelle: motif.libelle }),
				modalMsg: $translate.instant('MOTIFS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await MotifsService.deleteMotifById(motif.id);
					notification.success($translate.instant('MOTIFS.DELETE.SUCCESS'));

					selectedMotifId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.stateParams.id) {
						$state.go('motifs.list');
					}

					loadMotifs();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}

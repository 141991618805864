import * as _ from 'lodash';

export default class CentreGestionController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'CentreGestionService',
		'ModalService',
		'notification',
		'$translate',
		'moment',
		'$filter',
		'_',
		'globalizationManagementService',
		'MOPService'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		CentreGestionService,
		ModalService,
		notification,
		$translate,
		moment,
		$filter,
		_,
		globalizationManagementService,
		MOPService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.CentreGestionService = CentreGestionService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.moment = moment;
		this.$filter = $filter;
		this.isOpenTva = [];
		this.MOPService = MOPService;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.startLoading();
		this.previousMOP = this.MOPService.getCurrentMOP();
		this.MOPService.setMop([{ title: 'CENTRE_GESTION.CENTRE_PARAM', filename: 'CentreParametrage.pdf', application: 'gestion' }]);
		this.listes = {};
		if (this.domaine != 'Pays') {
			await this.getlistes();
		}

		// SL initialisation des 2 objets filtres
		this.frontFilters = {};
		this.filterToBack = {};
		this.filtresAppliques = false;
		this.hasToCheckPont = false;

		this.source = this.source || {};

		!this.dateApplication ? (this.dateApplication = this.moment().format(this.dateFormat)) : this.dateApplication;

		// SL maj du titre de la pop-up
		const LibelleDomaine = this.getDomaineToLibelle(this.domaine);
		this.titre = {
			domaineTitre: LibelleDomaine,
			libelleTitre: this.source.libelle || this.source.entete.libelle
		};

		// SL initialiser le filtre correspondant au domaine
		if (this.source.id) {
			this.initPopUp();
		}

		// SL si on a des données issues de la PréFa, on initialise les données du filtre
		if (this.source.preFaData) {
			this.getPreFaData(this.source.preFaData);
		}

		// SL si on a des données issues de la liste des factures vérifiant les ponts comptables, on initialise les données du filtre
		if (this.source.checkPont) {
			this.getCheckPontData(this.source.checkPont);
			this.hasToCheckPont = true;
		}

		// SL direct on met la date car POUR L'INSTANT pas de filtre date
		// soit la date du jour en passant par une entité
		// soit la date de PréFa si on vient de la PréFa
		this.filterToBack.date = this.dateApplication;

		// SL application du filtre
		await this.applyFilters();

		this.activeTab = 0;
		this.stopLoading();
	}

	async $onDestroy() {
		this.MOPService.resetMop();
		if (this.previousMOP) this.MOPService.setMop(this.previousMOP, false);
	}

	// SL méthode permettant initialiser le filtre correspondant au domaine
	initPopUp() {
		switch (this.domaine) {
			case 'SiteUtil':
				this.frontFilters.siteCommercial = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle,
					societeId: this.source.entete.societeId
				};
				break;
			case 'Societes':
				this.frontFilters.societes = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle
				};
				break;
			case 'SiteClient':
				this.frontFilters.siteClient = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle
				};
				break;
			case 'Chantiers':
				this.frontFilters.chantier = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle
				};
				break;
			case 'SiteProd':
				this.frontFilters.producteur = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle
				};
				break;
			case 'TVA':
				this.frontFilters.tva = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle
				};
				break;
			case 'SiteTransp':
				this.frontFilters.siteTransporteur = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle
				};
				break;
			case 'TypeProduit':
				this.frontFilters.typeProduit = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle
				};
				break;
			case 'TypeClient':
				this.frontFilters.typeClient = {
					id: this.source.id,
					libelle: this.source.libelle || this.source.entete.libelle
				};
				break;
			default:
			// message d'erreur ?
		}
	}

	// SL récupérer les données de la PréFacturation pour les injecter dans l'objet filtre
	getPreFaData(preFaData) {
		this.frontFilters.societes = {
			id: preFaData.societes.id,
			libelle: preFaData.societes.libelle
		};

		if (preFaData.siteCommercial) {
			this.frontFilters.siteCommercial = {
				id: preFaData.siteCommercial.id,
				libelle: preFaData.siteCommercial.libelle
			};
		}

		this.dateApplication = preFaData.dateApplication;
	}

	getCheckPontData(checkPont) {
		this.frontFilters.societes = {
			id: checkPont.societes.id,
			libelle: checkPont.societes.libelle
		};

		if (checkPont.idTVA) {
			const foundTVA = _.find(this.listes.tva, { id: checkPont.idTVA });
			if (foundTVA) {
				this.frontFilters.tva = foundTVA;
			}
		}

		if (checkPont.siteCommercial) {
			this.frontFilters.siteCommercial = {
				id: checkPont.siteCommercial.id,
				libelle: checkPont.siteCommercial.libelle
			};
		}

		if (checkPont.idTypeSociete) {
			const foundTySo = _.find(this.listes.typesSociete, { id: checkPont.idTypeSociete });
			if (foundTySo) {
				this.frontFilters.typeSociete = foundTySo;
			}
		}

		if (checkPont.siteClient) {
			this.frontFilters.siteClient = {
				id: checkPont.siteClient.id,
				libelle: checkPont.siteClient.libelle
			};
		}

		if (checkPont.chantier) {
			this.frontFilters.chantier = {
				id: checkPont.chantier.id,
				libelle: checkPont.chantier.libelle
			};
		}

		if (checkPont.idProducteur) {
			const foundProducteur = _.find(this.listes.producteurs, { id: checkPont.idProducteur });
			if (foundProducteur) {
				this.frontFilters.producteur = foundProducteur;
			}
		}

		if (checkPont.idTypeClient) {
			const foundTypeClient = _.find(this.listes.typesClient, { id: checkPont.idTypeClient });
			if (foundTypeClient) {
				this.frontFilters.typeClient = foundTypeClient;
			}
		}

		this.dateApplication = checkPont.dateApplication;
	}

	// SL Récupérer les listes permettant de remplir les combo box
	async getlistes() {
		await this.getTvas();
		await this.getTypesBenne();
		await this.getModesPaiement();
		await this.getPresentationFacture();
		await this.getTypesClient();
		await this.getModesTarages();
		await this.getControleCreationDap();
		await this.getModeleImpressionTicket();
		await this.getModeleImpressionBadge();
		await this.getChantLieu();
		await this.getTypesSociete();

		if (this.domaine == 'Produits') {
			await this.getProducteurs();
		}
	}

	// SL Méthode appelée lorsque l'on arrive sur le CdG (PréFa ou non) ou lorsque l'on clique sur "Appliquer" de la partie filtre
	async applyFilters() {
		this.filtresAppliques = true;
		// SL appel de la méthode permettant de convertir le frontFilter vers le backFilter
		await this.transformFrontFilterToBackFilterSL();

		// récupération des articles sélectionnables pour les articles liés et la liste des Taux de TVA
		await this.getElementsSelectionnables();

		// SL appel de la méthode permettant d'obtenir les paramètres du CdG
		await this.getGroupeParametreSL();
	}

	// SL réinitialise l'objet filtre
	async reinitialiserLesFiltres() {
		this.frontFilters = {};
		this.filterToBack = {};

		// SL suppression des listes d'articles liés
		if (this.listes.prestationsSelectionnables) {
			delete this.listes.prestationsSelectionnables;
		}
		if (this.listes.produitsSelectionnables) {
			delete this.listes.produitsSelectionnables;
		}
		if (this.listes.transportsSelectionnables) {
			delete this.listes.transportsSelectionnables;
		}
		if (this.listes.prestationsLiees) {
			delete this.listes.prestationsLiees;
		}
		if (this.listes.produitsLies) {
			delete this.listes.produitsLies;
		}
		if (this.listes.transportsLies) {
			delete this.listes.transportsLies;
		}

		_.remove(this.listes, { id: null });
		this.filtresAppliques = false;
		// this.getParametres();
		this.initPopUp();
		this.filterToBack.date = this.dateApplication;
		await this.applyFilters();
	}

	// SL méthode permettant de retourner pour chaque paramètre, la valeur
	async getGroupeParametreSL() {
		try {
			this.groupesParametres = null;
			this.groupesParametres = await this.CentreGestionService.getParametersWithFilter(this.domaine, this.source.id, this.filterToBack);
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.stopLoading();
		}
	}

	// SL méthode appelée quand on supprime un paramètre sur le niveau
	// pour recherche à nouveau le paramètre s'il est sur un niveau précédent
	async reinitialiserHeritage() {
		this.getGroupeParametreSL();
	}

	// SL convertir le domaine en libellé du domaine
	getDomaineToLibelle(domaine) {
		switch (domaine) {
			case 'SiteUtil':
				return this.$translate.instant('CENTRE_GESTION.SITECOMMERCIAL');
			case 'Societes':
				return this.$translate.instant('CENTRE_GESTION.SOCIETE');
			case 'SiteClient':
				return this.$translate.instant('CENTRE_GESTION.CLIENT');
			case 'Chantiers':
				return this.$translate.instant('CENTRE_GESTION.CHANTIER');
			case 'Produits':
				return this.$translate.instant('CENTRE_GESTION.PRODUITS');
			case 'Pays':
				return this.$translate.instant('CENTRE_GESTION.PAYS');
			case 'Prestation':
				if (
					this.source?.entete?.type === 'transport' ||
					this.source?.entete?.type === 'Transport' ||
					this.source.type === 'Transport' ||
					this.source.type === 'transport'
				) {
					// Change le domaine pour dissocier les pramètre pour les prestation et pour les types de transport (tout deux des prestation)
					this.domaine = 'TypeTransport';
					return this.$translate.instant('CENTRE_GESTION.TRANSPORT');
				}
				return this.$translate.instant('CENTRE_GESTION.PRESTATION');
			case 'TypeProduit':
				return this.$translate.instant('CENTRE_GESTION.TYPEPRODUIT');
			case 'SiteTransp':
				return this.$translate.instant('CENTRE_GESTION.TRANSPORTEUR');
			case 'TypeClient':
				return this.$translate.instant('CENTRE_GESTION.TYPECLIENT');
			default:
				return this.domaine;
		}
	}

	// SL permet de gérer une mauvaise saisie dans un des filtres
	resetFilterObject(pObject) {
		if (pObject) {
			if (pObject.id) {
				// ok
			} else {
				// si partiellement écrit, on a donc un string
				pObject.id = null;
			}
		} else {
			// si plus l'objet (supprimé à la main)
			pObject.id = null;
		}
	}

	// SL méthode alternative pour transformer l'objet filtre du front en objet à envoyer au back
	transformFrontFilterToBackFilterSL() {
		this.oldFilterToBack = angular.copy(this.filterToBack);
		this.filterToBack = {};
		const keyNames = Object.keys(this.frontFilters);
		for (let i = 0; i < keyNames.length; i++) {
			switch (keyNames[i]) {
				case 'societes':
					if (this.frontFilters.societes) {
						this.resetFilterObject(this.frontFilters.societes);
						this.filterToBack.societeId = this.frontFilters.societes.id;
					}
					break;
				case 'siteCommercial':
					if (this.frontFilters.siteCommercial) {
						this.resetFilterObject(this.frontFilters.siteCommercial);
						this.filterToBack.siteCommercialId = this.frontFilters.siteCommercial.id;
					}
					break;
				case 'siteTransporteur':
					if (this.frontFilters.siteTransporteur) {
						this.resetFilterObject(this.frontFilters.siteTransporteur);
						this.filterToBack.transporteurId = this.frontFilters.siteTransporteur.id;
					}
					break;
				case 'typeClient':
					if (this.frontFilters.typeClient) {
						this.resetFilterObject(this.frontFilters.typeClient);
						this.filterToBack.typeClientId = this.frontFilters.typeClient.id;
					}
					break;
				case 'siteClient':
					if (this.frontFilters.siteClient) {
						this.resetFilterObject(this.frontFilters.siteClient);
						this.filterToBack.clientId = this.frontFilters.siteClient.id;
					}
					break;
				case 'chantier':
					if (this.frontFilters.chantier) {
						this.resetFilterObject(this.frontFilters.chantier);
						this.filterToBack.chantierId = this.frontFilters.chantier.id;
					}
					break;
				case 'producteur':
					if (this.frontFilters.producteur) {
						this.resetFilterObject(this.frontFilters.producteur);
						this.filterToBack.producteurId = this.frontFilters.producteur.id;
					}
					break;
				case 'tva':
					if (this.frontFilters.tva) {
						this.resetFilterObject(this.frontFilters.tva);
						this.filterToBack.tvaId = this.frontFilters.tva.id;
					}
					break;
				case 'typeProduit':
					if (this.frontFilters.typeProduit) {
						this.resetFilterObject(this.frontFilters.typeProduit);
						this.filterToBack.typeProduitId = this.frontFilters.typeProduit.id;
					}
					break;
				case 'typeSociete':
					if (this.frontFilters.typeSociete) {
						this.resetFilterObject(this.frontFilters.typeSociete);
						this.filterToBack.typeSocieteId = this.frontFilters.typeSociete.id;
					}
					break;
				case 'typeBenne':
					if (this.frontFilters.typeBenne) {
						this.resetFilterObject(this.frontFilters.typeBenne);
						this.filterToBack.typeBenneId = this.frontFilters.typeBenne.id;
					}
					break;
				default:
					break;
			}
		}

		// SL maj de la date d'application
		this.filterToBack.date = this.dateApplication;
		switch (this.domaine) {
			case 'Pays': // SL cas de la fiche Pays
				this.filterToBack.paysId = this.source.id;
				break;
			case 'Produits': // SL cas de la fiche Produit
				this.filterToBack.produitId = this.source.id;
				this.filterToBack.typeProduitId = this.source.type?.id || this.source.entete.typeId;
				break;
			case 'Prestation': // SL cas des fiches Prestation et Transport
				if (this.source.entete.type === 'prestation') {
					this.filterToBack.prestationId = this.source.id;
				}
				break;
			case 'Chantiers':
				this.filterToBack.clientId = this.source.entete.idSite;
				break;
			case 'SiteClient':
				this.filterToBack.typeClientId = this.source.entete.typeClientId;
				break;
			case 'TypeTransport':
				this.filterToBack.transportId = this.source.id;
				break;
			case 'SiteUtil':
				this.filterToBack.societeId = this.source.entete.societeId;
				break;
			default:
				break;
		}

		// optimisation, pour n'afficher que les paramètres pont comptable
		// quand on vient de la vérification des codes pont de la clôture des factures
		this.filterToBack.hasToCheckPont = this.hasToCheckPont;
	}

	async getElementsSelectionnables() {
		if (this.domaine == 'Societes' || this.domaine == 'SiteUtil' || this.frontFilters.siteCommercial || this.frontFilters.societes) {
			if (
				this.filterToBack.societeId !== this.oldFilterToBack.societeId ||
				this.filterToBack.siteCommercialId !== this.oldFilterToBack.siteCommercialId
			) {
				//this.listes.produitsSelectionnables = await this.CentreGestionService.getProduitsSelectionnables(this.filterToBack);
				if (this.domaine != 'Societes') {
					// console.log("🚀 ~ file: centre.gestion.controller.js:495 ~ CentreGestionController ~ getElementsSelectionnables ~ this.filterToBack.societeId:", this.filterToBack, this.oldFilterToBack)
					this.listes.transportsSelectionnables = await this.CentreGestionService.getTransportsSelectionnables(this.filterToBack);
					this.listes.prestationsSelectionnables = await this.CentreGestionService.getPrestationsSelectionnables(this.filterToBack);
				}
			}
		}
	}

	//region Services Listes
	async getSites() {
		if (!this.listes.sites) {
			try {
				this.listes.sites = await this.CentreGestionService.getSites();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}

	async getModesPaiement() {
		if (typeof this.listes.modesPaiement === 'undefined') {
			try {
				this.listes.modesPaiement = await this.CentreGestionService.getModesPaiement();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}

	async getPresentationFacture() {
		if (!this.listes.presentationFactures) {
			try {
				this.listes.presentationFactures = await this.CentreGestionService.getListePourDomaine('Factures', 'AffPresFact');
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	async getTypesClient() {
		if (!this.listes.typesClient) {
			try {
				this.listes.typesClient = await this.CentreGestionService.getTypesClient();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	async getModesTarages() {
		if (!this.listes.modesTarages) {
			try {
				this.listes.modesTarages = await this.CentreGestionService.getModesTarage();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	async getControleCreationDap() {
		if (!this.listes.controleCreationDaps) {
			try {
				this.listes.controleCreationDaps = await this.CentreGestionService.getControleCreationDap();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	async getTransports() {
		if (!this.listes.transports) {
			try {
				this.listes.transports = await this.CentreGestionService.getTransports();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	async getModeleImpressionTicket() {
		if (!this.listes.modelesImpressionTickets) {
			try {
				this.listes.modelesImpressionTickets = await this.CentreGestionService.getModeleImpressionTicket();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	async getModeleImpressionBadge() {
		if (!this.listes.modelesImpressionBadges) {
			try {
				this.listes.modelesImpressionBadges = await this.CentreGestionService.getModeleImpressionBadge();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	getChantLieu() {
		this.listes.chantLieu = [
			{
				id: 'N',
				libelle: 'Non'
			},
			{
				id: 'C',
				libelle: 'Chantier'
			},
			{
				id: 'L',
				libelle: 'Lieu'
			},
			{
				id: 'RC',
				libelle: 'Référence client'
			}
		];
	}
	async getAllSites() {
		if (!this.listes.sites) {
			try {
				this.listes.sites = await this.CentreGestionService.getAllSites();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}

	async getTvas() {
		if (!this.listes.tva) {
			try {
				this.listes.tva = await this.CentreGestionService.getTvas();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	async getTypesSociete() {
		if (!this.listes.typesSociete) {
			try {
				this.listes.typesSociete = await this.CentreGestionService.getTypesSociete('Societes');
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	async getTypesBenne() {
		if (!this.listes.typesBenne) {
			try {
				this.listes.typesBenne = await this.CentreGestionService.getTypesBenne();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}

	async getProducteurs() {
		if (!this.listes.producteurs) {
			try {
				this.listes.producteurs = await this.CentreGestionService.getProducteurs();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}
	//endregion

	// region Base des pages
	close() {
		this.close();
	}
	saveParams() {
		try {
			this.modalInstance.dismiss();
		} catch (err) {
			this.notification.error(err);
		}
	}

	cancel() {
		this.modalInstance.dismiss();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
	//endregion
}

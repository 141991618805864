import CommandePopupController from './commande.popup.controller';

export default {
	bindings: {
		modalInstance: '=',
		idCommande: '=',
		paiement: '='
	},
	template: require('./commande.popup.html'),
	controller: CommandePopupController
};

import { StateService } from '@uirouter/angularjs';
import { LoDashStatic } from 'lodash';
import SyntheseEntityService from '../../../../services/synthese.entity.service';
import SyntheseSettingService from '../../../../services/synthese.setting.service';

export abstract class Synthese {
	$scope: ng.IScope;
	SyntheseEntityService: SyntheseEntityService;
	SyntheseSettingService: SyntheseSettingService;
	$state: StateService;
	$translate: any;
	$timeout: ng.ITimeoutService;
	_: LoDashStatic;
	uiGridConstants: uiGrid.IUiGridConstants;
	uiGridTemplate: any;
	watcher: (() => void)[];
	result: any;
	gridApi: uiGrid.IGridApi;
	grid: any;
	loading: boolean;
	synthese: Synthese;
	changeAgg: boolean;
	save: (arg0: any, arg1: boolean, arg2: boolean, arg3: boolean) => void;
	generate: () => void;
	refresh: boolean = false;
	setting: any;
	formatters: any = {};
	moment: any;
	maxAttemps: number = 10;
	attemps: number = 0;
	name: string;
	notification: any;
	typeSynthese = 0;
	gridOptions: any = {
		showGridFooter: true,
		enableGridMenu: true,
		enableRowHashing: false,
		flatEntityAccess: true,
		showColumnFooter: true,
		enableSorting: true,
		enableFiltering: true,
		// PDF Options
		exporterMenuPdf: false,
		exporterPdfDefaultStyle: { fontSize: 9 },
		exporterPdfTableStyle: { margin: [30, 30, 30, 30] },
		exporterPdfTableHeaderStyle: { fontSize: 10, bold: true, italics: true, color: 'red' },
		exporterPdfHeader: { text: 'My Header', style: 'headerStyle' },
		exporterPdfFooter: function (currentPage: any, pageCount: any) {
			return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
		},
		exporterPdfCustomFormatter: function (docDefinition: any) {
			docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
			docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
			return docDefinition;
		},

		exporterPdfOrientation: 'landscape',
		exporterPdfPageSize: 'A4',
		exporterPdfMaxGridWidth: 720,
		// End Pdf Options
		exporterMenuExcel: true,
		exporterMenuSelectedData: false,
		exporterMenuVisibleData: false,
		excessColumns: 12,
		excessRows: 12,
		groupingShowCounts: false
	};
	SyntheseEntitiesService: any;
	constructor(
		$scope: ng.IScope,
		SyntheseEntityService: SyntheseEntityService,
		SyntheseSettingService: SyntheseSettingService,
		$state: StateService,
		$translate: any,
		$timeout: ng.ITimeoutService,
		_: LoDashStatic,
		uiGridConstants: uiGrid.IUiGridConstants,
		uiGridTemplate: any,
		moment: any,
		name: string,
		notification: any
	) {
		this.$scope = $scope;
		this.SyntheseEntityService = SyntheseEntityService;
		this.SyntheseSettingService = SyntheseSettingService;
		this.$state = $state;
		this.$translate = $translate;
		this.$timeout = $timeout;
		this._ = _;
		this.uiGridConstants = uiGridConstants;
		this.uiGridTemplate = uiGridTemplate;
		this.moment = moment;
		this.gridOptions.exporterCsvFilename = name + '.csv';
		this.gridOptions.exporterExcelFilename = name + '.xlsx';
		this.gridOptions.exporterExcelSheetName = name;
		this.gridOptions.groupingNullLabel = this.$translate.instant('NONE');
		this.notification = notification;
	}

	protected handleResize() {
		if (this.gridApi) {
			this.$timeout(() => {
				this.gridApi.core.handleWindowResize();
			}, 50);
		}
	}

	protected refreshGrid() {
		this.refresh = true;
		this.$timeout(() => {
			this.refresh = false;
		}, 0);
	}

	private resetAttemp() {
		this.attemps = 0;
	}

	protected setWatcherContent(callback?: () => void) {
		if (!this.gridApi && this.attemps < this.maxAttemps) {
			this.attemps++;
			this.$timeout(() => {
				this.setWatcherContent(callback);
			}, 500);
			return;
		}
		if (this.attemps === this.maxAttemps) {
			console.error('To many attemps to access gridApi. Is grid initialized ?');
			this.resetAttemp();
			return;
		}
		if (callback) {
			callback();
		}
	}

	setFooter() {
		const footer: any[] = [];
		const columns = this.result.results[0].grid.body[0].columns;
		const keys = Object.keys(columns);
		this.result.results[0].grid.footer.forEach((item: any) => {
			const cols = [];
			let notSet: boolean = true;
			for (let i = 0; i < keys.length; i++) {
				const col = keys[i];
				if (!Object.prototype.hasOwnProperty.call(item.columns, col)) {
					//if(!item.columns.hasOwnProperty(col)) {
					cols.push('');
				} else {
					if (notSet && cols[i - 1] == '') {
						console.log(notSet, item.lineName);
						cols[i - 1] = item.lineName;
					}
					cols.push(item.columns[col].value);
					notSet = false;
				}
			}
			footer.push(cols);
		}, this);
		// return array;
		return footer;
	}

	listenToColumnPinnedEvent() {
		const api = this.gridApi;
		this.gridApi.pinning.on.columnPinned(this.$scope, function (colDef: any) {
			setTimeout(() => {
				//délai pour que l'attibut "filterable" se mette à jour
				const gridCol = api.grid.columns.find(({ colDef: { name } }) => name === colDef.name);
				if (gridCol && !gridCol.filterable) {
					//L'attribut a disparu donc le filtre aussi. OFF/ON de la colonne pour le faire réapparaître
					//source de référence : https://github.com/SEED-platform/seed/pull/4473/commits/5856a377bcc4f6ba7106f416ef55f6732a61063a
					gridCol.colDef.visible = false;
					api.grid.refresh();
					setTimeout(() => {
						gridCol.colDef.visible = true;
						api.grid.refresh();
					});
				}
			});
		});
	}

	protected onRegisterApi = (gridApi: uiGrid.IGridApi) => {
		this.gridApi = gridApi;
		if (this.synthese && this.synthese.setting) {
			this.$timeout(() => {
				const setting = JSON.parse(this.synthese.setting.setting);
				gridApi.saveState.restore(this.$scope, setting);
			}, 10);
		}
		this.listenToColumnPinnedEvent();
		//gridApi.core.refresh();
	};

	protected roundTreeAggregation(agg: any) {
		if (agg.type && agg.type === 'count' && agg.groupVal && agg.groupVal === 'Null') {
			agg.rendered = 'Aucun';
			agg.groupVal = 'Aucun';
			return;
		}
		if (!agg.type || agg.type === 'count') return;
		if (typeof agg.value === 'number') agg.value = agg.value.round(2);
		if (typeof agg.value === 'undefined' || agg.value === null) {
			agg.value = '';
			agg.label = '';
		}
		return (agg.rendered = agg.label + agg.value);
	}

	protected toColumnDefinition(header: any, callback: (header: any, columnDef: any) => void) {
		const columnDef: any = {};
		if (header && header.grouping) {
			columnDef.grouping = { groupPriority: header.grouping.groupPriority };
			columnDef.sort = {
				direction: this.uiGridConstants.ASC,
				priority: 0
			};
		}
		if (header && header.filter) {
			columnDef.filter = {
				placeholder: this.$translate.instant(header.filter.placeholder)
			};
		}
		if (header && header.filters) {
			columnDef.filters = header.filters.map((f: any) => {
				f.placeholder = this.$translate.instant(f.placeholder);
				return f;
			});
		}
		columnDef.displayName = header.displayName;
		columnDef.pinnedLeft = header.pinnedLeft;
		columnDef.pinnedRight = header.pinnedRight;
		if (header.sort) {
			columnDef.sort = header.sort;
		}
		if (header.type) {
			columnDef.type = header.type;
			if (columnDef.type == 'date') columnDef.sortingAlgorithm = this.dateSorting;
		}
		if (header.group) {
			columnDef.group = header.group;
		}
		columnDef.headerCellTemplate = this.uiGridTemplate.synthentsHeader;
		columnDef.minWidth = 80;
		//columnDef.maxWidth = 120;
		columnDef.width = '*';
		columnDef.originalField = header.field;
		columnDef.field = `columns.${header.field}.value`;
		columnDef.headerCellClass = 'bg-primary';
		columnDef.cellFilter = 'gridRound:2';
		columnDef.cellClass = this.setCellClass;
		columnDef.footerCellFilter = 'gridRound:2';
		columnDef.customTreeAggregationFinalizerFn = this.roundTreeAggregation;
		if (callback) {
			callback(header, columnDef);
		}
		return columnDef;
	}

	dateSorting(d1: string, d2: string) {
		console.log(d1, d2);
		if (!d1 && !d2) return;
		if (!d1) return -1;
		if (!d2) return 1;
		const parts1 = d1.split('/');
		const date1 = new Date(parseInt(parts1[2], 10), parseInt(parts1[1], 10) - 1, parseInt(parts1[0], 10)).getTime();

		const parts2 = d2.split('/');
		const date2 = new Date(parseInt(parts2[2], 10), parseInt(parts2[1], 10) - 1, parseInt(parts2[0], 10)).getTime();

		if (date1 < date2) {
			return -1;
		} else if (date1 > date2) {
			return 1;
		} else {
			return 0;
		}
	}

	// TODO enlever variable avec underscore. !!! attention à l'utilisation
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected setCellClass(_grid: any, row: any, col: any, _rowRenderIndex: any, _colRenderIndex: any) {
		const f = col.colDef.originalField;
		if (!row.entity.columns) {
			return;
		}
		const o = row.entity.columns[f];
		if (!o) {
			return;
		}
		if (o.type === 1) {
			return 'grid-cell-right';
		}
	}

	private columnAdress(col: number): string {
		if (col <= 26) {
			return String.fromCharCode(col + 64);
		}
		let div = col / 26;
		let mod = col % 26;
		if (mod == 0) {
			mod = 26;
			div--;
		}
		return this.columnAdress(div) + this.columnAdress(mod);
	}

	protected createFooterTemplate(footer: any) {
		let template = '';
		for (let i = 0; i < footer.length; i++) {
			template += this.uiGridTemplate.synthentsFooter(i);
		}
		return template;
	}

	protected aggregationFooter(_row: any, fieldValue: any, footer: any) {
		const col = fieldValue.colDef;
		const res = [];
		for (let i = 0; i < footer.length; i++) {
			const f = footer[i];
			const val = f.columns[col.originalField] ? f.columns[col.originalField].value : null;
			if (typeof val === 'number') val.toFixed(2);
			res[i] = {
				name: `${f.lineName} : `,
				val: `${val ? val : '-'}`,
				style: {
					height: '30px',
					background: f.background,
					color: f.text
				}
			};
		}
		return res;
	}

	protected setColumnDefs({ header, footer }: { header: any; footer: any }) {
		return header.map((x: any) =>
			this.toColumnDefinition(x, (h, column) => {
				column.footerCellTemplate = this.createFooterTemplate(footer);
				if (!h.isAff) {
					column.aggregationType = (aggregation: any, fieldValue: any) => this.aggregationFooter(aggregation, fieldValue, footer);
				}
			})
		);
	}

	protected SetFooterCellClass() {}
	abstract saveSynthese(): void;
	abstract genSynth(): void;

	async ExportPdf(isPaysage: boolean) {
		try {
			const footer = this.setFooter();
			const grid = this.result.results[0].grid;
			const resultat = await this.SyntheseEntityService.excelExport(grid, footer, this.synthese, true, isPaysage);
			if (resultat) {
				const data = resultat.data;
				const headers = resultat.headers;
				// headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				const blob = new Blob([data], { type: contentType });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);
				linkElement.setAttribute('download', 'export' + '.pdf');

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			}
		} catch (ex) {
			this.notification.error("Microsoft Office n'a pas été détecté sur votre serveur. Veuillez contacter votre administrateur.");
		}
	}

	async ExportExcel() {
		const footer = this.setFooter();
		const grid = this.result.results[0].grid;
		const resultat = await this.SyntheseEntityService.excelExport(grid, footer, this.synthese, false);
		if (resultat) {
			const data = resultat.data;
			const headers = resultat.headers;
			// headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');
			const blob = new Blob([data], { type: contentType });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', 'export' + '.xlsx');

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
		}
	}
}

import ConfigurationEmailSmtpFormController from './configuration.email.smtp.form.controller';
import ConfigurationEmailSmtpFormHtml from './configuration.email.smtp.form.html';
export default {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	// templateUrl: 'app/common/configuration-email/components/configuration-email-smtp-form/configuration.email.smtp.form.html',
	template: ConfigurationEmailSmtpFormHtml,
	controller: ConfigurationEmailSmtpFormController
};

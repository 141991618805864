import * as angular from 'angular';
import { NgModule } from '../../core/decorators';

//Module
import MassiaGaugeModule from './massia-gauge';
import { MassiaMultiSelectModule } from './massia-multi-select/massia.multi.select.module';

//Component
import { MassiaAccordionController } from './massia-accordion/massia.accordion.controller';
import { MassiaFormController } from './massia-form/massia.form.controller';
import { MassiaTableController } from './massia-table/massia.table.controller';
import { MassiaFooterPaginationController } from './massia-footer-pagination/massia.footer.pagination.controller';
import { MassiaSliderDetailController } from './massia-slider-detail/massia.slider.detail.controller';
import { MassiaSliderContainerController } from './massia-slider-container/massia.slider.container.controller';
import { MassiaChartController } from './massia-chart/massia.chart.controller';
import { MassiaEssaiGeneriqueController } from './massia-essai-generique/massia.essai.generique.controller';
import { MassiaEraseFiltersController } from './massia-erase-filters/massia.erase.filters.controller';
import { MopServiceButtonController } from './mop-service-button/mop.service.button.controller';
import { MassiaEssaiIteratifController } from './massia-essai-iteratif/massia.essai.iteratif.controller';
import { MassiaPrintModalController } from './massia-print-modal/massia.print.modal';
import { MassiaEssaiExcelController } from './massia-essai-excel/massia.essai.excel.controller';
import { MassiaEssaiExcelPlvController } from './massia-essai-excel-plv/massia.essai.excel.plv.controller';
import { MassiaPageLoadingController } from './massia-page-loading/massia.page.loading.controller';
import { MassiaAccordionGroupController } from './massia-accordion-group/massia.accordion.group.controller';
import { MassiaSwitchController } from './massia-switch/massia.switch.controller';
import { MassiaSlider } from './massia-slider/massia.slider.controller';
import { MassiaGraphConfigController } from './massia-graph-config/massia.graph.config.controller';
import { MassiaGraphController } from './massia-graph/massia.graph.controller';
import { MassiaFieldsetController } from './massia-fieldset/massia.fieldset.controller';
import { MassiaDynamicComponent } from './massia-dynamic-component/massia.dynamic.component.controller';
import ExcelModalValidator, { ExcelFormValidator } from './massia-essai-excel/massia.excel.validator';
import { MassiaElectronConfigurationController } from './massia-electron-configuration/massia.electron.configuration.controller';
import MassiaTypeAideController from './massia-type-aide/massia.type.aide.controller';
import { MassiaMapController } from './massia-map/massia.map.controller';

//Directive
import { MassiaUploadChange } from './massia-directive/upload.change.directive';
import { MassiaColorPicker } from './massia-directive/massia.color.picker.directive';
import { MassiaVirtualScroll } from './massia-directive/massia.virtual.scroll.directive';
import { TabVerticalInput } from './massia-directive/tab.vertical.input.directive';
import { MassiaScrollDrag } from './massia-directive/massia.scroll.drag.directive';
import { MassiaTableMultiselect } from './massia-directive/massia.table.multiselect.directive';
import { MassiaClickOutside } from './massia-directive/on.click.outside.directive';
import { ContextMenu } from './massia-directive/massia.context.menu.directive';
import { MassiaInjectDirective } from './massia-directive/massia.inject.directive';
// import { MassiaFixedHeader } from './massia-directive/massia.fixed.header.directive';
import { MassiaFixedTable } from './massia-directive/massia.fixed.table.directive';
import { MassiaPopover } from './massia-directive/massia.popover.directive';
import { MassiaCustomPagination } from './massia-directive/massia.custom.pagination.directive';

//Pipe
import { IstevenFormat } from './massia-pipe/massia.istevenFormat.pipe';
import { SelectInIsteven } from './massia-pipe/massia.selectInIsteven.pipe';
import { IsNumber } from './massia-pipe/massia.isNumber.pipe';
import { Conditional } from './massia-pipe/massia.conditional.pipe';
import { slice } from './massia-pipe/massia.slice.pipe';
import { IsArray } from './massia-pipe/massia.isarray.pipe';
import { gridRound } from './massia-pipe/massia.gridRound.pipe';
import { InputMask } from './massia-pipe/massia.input-mask.pipe';

//Service
import { MassiaConsoleService } from './massia-service/console.service';
import { ElectronService } from './massia-service/electron.service';
import { CalendarComponentService } from './massia-service/calendar.component.service';
import { DialogService } from './massia-service/dialog.service';
import { Interpreteur } from './massia-formules/interpreteur';
import { RouterHistoryService } from './massia-service/router.history.service';
import { ExcelService } from './massia-service/excel.service';
import { TypeAideService } from './massia-service/type.aide.service';

//Constant
import { contextMenu } from './massia-constant/context-menu.constant';
import { AggregationService } from './massia-service/aggregation.service';
import { WebWorkerService } from './massia-service/webworker.service';
import { LoggerService } from './massia-service/logger.service';
import { ChartComponent } from './chart/chart.component';

import { LinkBalanceController } from './massia-link-balance/link.balance.component.controller';
import { MassiaFilterModule } from './massia-filter/massia-filter.module';
const moduleName = 'app.massia.massia.component';

export default moduleName;

@NgModule({
	id: moduleName,
	declarations: [
		MassiaSlider,
		MassiaChartController,
		MassiaEssaiGeneriqueController,
		MassiaEraseFiltersController,
		MopServiceButtonController,
		MassiaEssaiIteratifController,
		MassiaEssaiExcelController,
		MassiaEssaiExcelPlvController,
		MassiaSwitchController,
		MassiaSliderContainerController,
		MassiaGraphController,
		MassiaGraphConfigController,
		MassiaFieldsetController,
		MassiaDynamicComponent,
		MassiaColorPicker,
		MassiaAccordionController,
		MassiaAccordionGroupController,
		MassiaFormController,
		MassiaTableController,
		MassiaFooterPaginationController,
		MassiaSliderDetailController,
		MassiaPrintModalController,
		MassiaPageLoadingController,
		MassiaElectronConfigurationController,
		MassiaMapController,
		IstevenFormat,
		SelectInIsteven,
		IsArray,
		IsNumber,
		Conditional,
		slice,
		MassiaVirtualScroll,
		MassiaFixedTable,
		MassiaUploadChange,
		TabVerticalInput,
		MassiaScrollDrag,
		MassiaInjectDirective,
		ContextMenu,
		MassiaClickOutside,
		MassiaTableMultiselect,
		MassiaPopover,
		MassiaCustomPagination,
		gridRound,
		ChartComponent,
		MassiaTypeAideController,
		LinkBalanceController,
		InputMask
	],
	imports: ['ngSanitize', MassiaGaugeModule, MassiaFilterModule, MassiaMultiSelectModule],
	providers: [
		MassiaConsoleService,
		ExcelService,
		ElectronService,
		CalendarComponentService,
		RouterHistoryService,
		Interpreteur,
		DialogService,
		AggregationService,
		WebWorkerService,
		LoggerService,
		{ provide: 'ExcelModalValidator', useFactory: ExcelModalValidator },
		{ provide: 'ExcelFormValidator', useFactory: ExcelFormValidator },
		{ provide: 'ContextMenuEvents', useValue: contextMenu },
		TypeAideService
	]
})
export class LibModule {
	/* @ngInject */
	static config($translatePartialLoaderProvider: angular.translate.ITranslatePartialLoaderProvider) {
		$translatePartialLoaderProvider.addPart('essai.excel');
		$translatePartialLoaderProvider.addPart('graph');
	}
}

const serviceUrl = `${__configuration.apiUrl}/massia/external/apigeo`;

export default class ApiGeoService {
	/* @ngInject */
	constructor($http, moment, globalizationManagementService) {
		this.$http = $http;
		this.moment = moment;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async getGeoByCoord(lon, lat) {
		const params = {
			longitude: JSON.stringify(lon),
			latitude: JSON.stringify(lat)
		};
		const res = await this.$http.get(`${serviceUrl}/get-geo-by-coord`, {
			params: params
		});
		console.log(res.data);
		return res.data;
	}

	async getGeoByAdresse(adresse) {
		const params = {
			adresse: JSON.stringify(adresse)
		};
		const res = await this.$http.get(`${serviceUrl}/get-geo-by-adresse`, {
			params: params
		});
		console.log(res.data);
		return res.data;
	}

	async getCadastreByCoord(lon, lat) {
		const params = {
			longitude: JSON.stringify(lon),
			latitude: JSON.stringify(lat)
		};
		const res = await this.$http.get(`${serviceUrl}/get-parcelle-by-coord`, {
			params: params
		});
		console.log(res.data);
		return res.data;
	}
}

import ConfigurationEmailConfiguration from './_config/configuration.email.config';
import ConfigurationEmailRoutes from './_config/configuration.email.routes';

// Services
import ConfigurationEmailService from './services/configuration.email.service';

// Components
import ConfigurationEmailListComponent from './components/configuration-email-list';
import ConfigurationEmailSmtpFormComponent from './components/configuration-email-smtp-form';
import ConfigurationEmailFormComponent from './components/configuration-email-form';

// Validator
import ConfigurationEmailFormValidator from './components/configuration-email-form/configuration.email.form.validator';
import ConfigurationEmailSmtpFormValidator from './components/configuration-email-smtp-form/configuration.email.smtp.form.validator';

const moduleName = 'app.massia.common.configuration.email';

angular
	.module(moduleName, [])
	.config(ConfigurationEmailConfiguration)
	.config(ConfigurationEmailRoutes)
	.service('ConfigurationEmailService', ConfigurationEmailService)
	.component('configurationEmailList', ConfigurationEmailListComponent)
	.component('configurationEmailSmtpForm', ConfigurationEmailSmtpFormComponent)
	.component('configurationEmailForm', ConfigurationEmailFormComponent)
	.factory('ConfigurationEmailSmtpFormValidator', ConfigurationEmailSmtpFormValidator)
	.factory('ConfigurationEmailFormValidator', ConfigurationEmailFormValidator);

export default moduleName;

VehiculesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'VehiculesService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function VehiculesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	VehiculesService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;

	let previousTableState;
	let selectedVehiculeId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.vehicules = [];
	vm.isEnabled = true;
	vm.previousIsEnabled = true;

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectVehicule = selectVehicule;
	vm.isVehiculeSelected = isVehiculeSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadVehicules = loadVehicules;
	vm.deleteVehicule = deleteVehicule;
	vm.activateVehicule = activateVehicule;
	vm.setSelected = setSelected;
	vm.exporter = exporter;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([{ title: 'VEHICULES.GESTION_VEHICULE', filename: 'GestionVehicules.pdf', application: 'gestion' }]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc() {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(vehicule) {
		if (vehicule && vehicule.id) {
			vm.selectedVehiculeId = $stateParams.id;
			vm.lastSelected = vm.selectedVehiculeId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedVehiculeId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state.current.name === 'vehicules.list.detail' && $stateParams.id;
	}

	function selectVehicule(vehicule) {
		if (vehicule && vehicule.id) {
			if (vm.selectedVehiculeId != vehicule.id) {
				vm.selectedVehiculeId = vehicule.id;
				showDetail(vehicule.id);
			} else {
				vm.selectedVehiculeId = undefined;
				closeDetail();
			}
		}
	}

	function isVehiculeSelected(vehicule) {
		return vehicule && vehicule.id && selectedVehiculeId === vehicule.id;
	}

	function showDetail(vehiculeId) {
		$state.go('vehicules.list.detail', { id: vehiculeId });
	}

	function closeDetail() {
		selectedVehiculeId = undefined;
		$state.go('vehicules.list');
	}

	async function loadVehicules(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'vehicules.list') {
			$state.go('vehicules.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		if (vm.isEnabled != vm.previousIsEnabled) {
			pagination.skip = 0;
			vm.previousIsEnabled = vm.isEnabled;
		}

		vm.vehicules = [];

		try {
			const data = await VehiculesService.getVehicules(vm.isEnabled, filters, sorts, pagination);

			vm.vehicules = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteVehicule(vehicule) {
		if (vehicule && vehicule.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('VEHICULES.DELETE.TITLE'),
				modalMsg: $translate.instant('VEHICULES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await VehiculesService.deleteVehiculeById(vehicule.id);
					notification.success($translate.instant('VEHICULES.DELETE.SUCCESS'));

					selectedVehiculeId = undefined;

					if (vm.params.id) {
						vm.state.go('vehicules.list');
					}

					loadVehicules();
				} catch (ex) {
					if (ex.status == 500) {
						notification.error('VEHICULES.DELETE.NOTDELETABLE');
					} else notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function activateVehicule(idVehicule) {
		await VehiculesService.activateVehicule(idVehicule);
		loadVehicules();
	}

	async function exporter(modeImpression) {
		/*let itemsCount=previousTableState.pagination.itemsCount;
        let limite = 1000;
        if(itemsCount>limite)
        {
            let txtError = $translate.instant('VEHICULES.TROP_ENREGISTREMENTS_FILTRER', {itemsCount: limite});
            notification.error(txtError);
            return false;
        }
        else {*/
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_vehicules';
			let resultat = undefined;
			try {
				resultat = await VehiculesService.exportListeVehicules(modeImpression, filters, sorts);
			} catch (ex) {
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('VEHICULES.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
					//console.log(ex)
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
		//}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}

export default class FacturesPrintController {
	/* @ngInject*/
	constructor($scope, $state, $stateParams, ModalService, FacturesService, notification, $translate, $filter, _, $uibModal) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ModalService = ModalService;
		this.FacturesService = FacturesService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this.$uibModal = $uibModal;
	}

	async $onInit() {
		this.startLoading();

		this.printOption = {
			isRectoVerso: false,
			isFacture: true,
			isTraite: false,
			isReleve: false,
			isTraiteFusion: false,
			isDuplicata: false,
			haveSeparateFiles: false,
			showFactureSoldee: false
		};
		this.invalidesChecked = false;
		if (!this.allFacturesOptions || !this.allFacturesOptions.count || this.allFacturesOptions.count == this.source.length) {
			this.factureInvalides = await this.FacturesService.getFactureInvalides(this.source);
			this.invalidesChecked = true;
		}

		this.stopLoading();
	}

	async print() {
		if (this.source.length > 0) {
			this.startLoading();
			const fileName = Date.now();
			if (!this.printOption.isTraite) {
				this.printOption.isTraiteFusion = false;
			}

			try {
				let resultat = null;
				if (this.allFacturesOptions && this.allFacturesOptions.count > 0) {
					resultat = await this.FacturesService.printAll(this.source, fileName, this.printOption, this.allFacturesOptions);
				} else resultat = await this.FacturesService.print(this.source, fileName, this.printOption);

				const data = resultat.data;
				let headers = resultat.headers;

				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					linkElement.setAttribute('download', fileName + (this.printOption.haveSeparateFiles ? '.zip' : '.pdf'));

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
					//console.log(ex);
				} finally {
					const response = await this.FacturesService.getMessageErreur();
					if (response) {
						this.ModalService.info({
							modalTitle: this.$translate.instant('FACTURES.BREADCRUMBS.FACTURES_LIST'),
							modalMsg: response,
							headerClass: 'modal-info'
						});
					}
					this.stopLoading();
					this.modalInstance.close(true);
				}
			} catch (ex) {
				console.log(ex);

				if (!('TextDecoder' in window)) {
					this.notification.error('Sorry, this browser does not support TextDecoder...');
				}

				const enc = new TextDecoder('utf-8');
				this.notification.error(enc.decode(ex.data));
			}
		}
	}

	async cancel() {
		this.modalInstance.dismiss();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

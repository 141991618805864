ZonesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'ZonesService',
	'notification',
	'MOPService',
	'MassiaApplicationService',
	'$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function ZonesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	ZonesService,
	notification,
	MOPService,
	MassiaApplicationService,
	$transitions
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedZoneId = undefined;

	vm.loading = false;

	vm.params = $stateParams;
	vm.state = $state;
	vm.MOPService = MOPService;
	vm.zones = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	// vm.selectZone = selectZone;
	vm.isZoneSelected = isZoneSelected;
	// vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadZones = loadZones;
	vm.setSelected = setSelected;
	vm.deleteZone = deleteZone;

	function init() {
		vm.MOPService.setMop([{ title: 'ZONES.BREADCRUMBS.ZONES_LIST', filename: 'Zones.pdf', application: 'gestion' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "zones.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	init();

	function stateChangeSuccessFunc() {
		/*if (toState.name === 'zones.list') {
            selectedZoneId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }*/
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(zone) {
		if (zone && zone.id) {
			selectedZoneId = $stateParams.id;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			selectedZoneId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'zones.list.detail' && $stateParams && $stateParams.id;
	}

	// function selectZone(zone) {
	//     if (zone && zone.id) {
	//         if (selectedZoneId !== zone.id){//vm.selectedZoneId !== zone.id) {
	//             //vm.selectedZoneId = zone.id;
	//             showDetail(zone.id);
	//         } else {
	//             //vm.selectedZoneId = undefined;
	//             closeDetail();
	//         }
	//     }
	// }

	function isZoneSelected(zone) {
		return zone && zone.id && selectedZoneId === zone.id;
	}

	// function showDetail(zoneId) {
	//     //vm.slideMargin['margin-right'] = vm.slideWidth;
	//     selectedZoneId = zoneId;
	//     $state.go('zones.list.detail', {id: zoneId});
	// }

	function closeDetail() {
		selectedZoneId = undefined;
		$state.go('zones.list');
	}

	async function deleteZone(zone) {
		if (zone && zone.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('ZONES.DELETE.TITLE', { code: zone.libelle }),
				modalMsg: $translate.instant('ZONES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await ZonesService.deleteZoneById(zone.id);
					notification.success($translate.instant('ZONES.DELETE.SUCCESS'));

					selectedZoneId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						$state.go('zones.list');
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					loadZones();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function loadZones(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "zones.list", et on ferme la vue détail d'un zone
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'zones.list') {
			$state.go('zones.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.zones = [];

		try {
			const data = await ZonesService.getZones(filters, sorts, pagination);
			vm.zones = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}

import { Component } from '../../../core/decorators';
import L from 'leaflet/dist/leaflet';
import 'leaflet-easyprint';
import 'leaflet-measure/dist/leaflet-measure.fr';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.min';
import MassiaMapTemplate from './massia.map.tpl.html';

@Component({
	selector: 'massia-map',
	bindings: {
		mapHeight: '=',
		mapWidth: '=',
		enableMarker: '=',
		markOnClick: '=',
		onMapClick: '<'
	},
	template: MassiaMapTemplate
})
export class MassiaMapController {
	constructor() {
		this.mapHeight = this.mapHeight ? this.mapHeight : 300;
		this.mapWidth = this.mapWidth ? this.mapWidth : 500;
		this.zoom = 5;
		this.longitude = this.longitude ? this.longitude : 3.5;
		this.latitude = this.latitude ? this.latitude : 45.5;
		console.log(this.latitude, this.longitude);
		this.map = {};
	}

	async $onInit() {
		await this.initCarte();
	}

	async initCarte() {
		let mapController = this;
		$('#massia-map-container', async () => {
			this.map = L.map('massia-map-container').setView([this.latitude, this.longitude], this.zoom); //par défaut
			console.log(this, this.map);

			this.map.scrollWheelZoom.disable();

			//Couches
			const Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				//z:niveau de zoom, x et y coordonnées du pavé
				attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				maxZoom: 20 //zoom maximum de la carte
			});
			Mapnik.addTo(this.map);

			this.blueMarker = L.icon({
				iconUrl: 'assets/img/pin_GO.png',
				iconSize: [64, 64], // size of the icon
				iconAnchor: [8, 55] // point of the icon which will correspond to marker's location
			});
			this.marqueurSite = L.marker([this.latitude, this.longitude], {
				icon: this.blueMarker,
				draggable: false /*, class:'leaflet-marker-pane'*/
			});
			if (this.enableMarker) {
				this.marqueurSite.addTo(this.map);
			}

			this.map.on(
				'click',
				function (event) {
					if (this.markOnClick) {
						this.marqueurSite.setLatLng([event.latlng.lat, event.latlng.lng]);
					}
					if (mapController.onMapClick) {
						mapController.onMapClick(event);
					}
				},
				this
			);
		});
	}
}

export default class PreviewLoginController {
	/* @ngInject */
	constructor($scope) {
		this.$scope = $scope;
		this.displayModal = false;
		this.defaultBg = '/assets/img/portail/portail_default_login_bg.jpg';
	}

	async $onInit() {
		this.loginBg = this.defaultBg;
		this.logo = ''; // Initialisez la variable logo
		const that = this;
		this.$scope.$watch(
			function () {
				return {
					background: that.background,
					logo: that.logo
				};
			},
			function (newValues, oldValues) {
				if (newValues.background !== oldValues.background) {
					if (newValues.background == null || newValues.background === '') {
						that.loginBg = that.defaultBg;
					} else {
						that.loginBg = newValues.background;
					}
				}
				if (newValues.logo !== oldValues.logo) {
					that.logo = newValues.logo;
				}
			},
			true
		);
	}

	closeModal() {
		this.displayModal = false;
	}
}

//import jsPDF from 'jspdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export default class TarifController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'SocietesService',
		'TarifsService',
		'SocietesTypesService',
		'MOPService',
		'ModalService',
		'notification',
		'$translate',
		'moment',
		'$filter',
		'_',
		'globalizationManagementService'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		SocietesService,
		TarifsService,
		SocietesTypesService,
		MOPService,
		ModalService,
		notification,
		$translate,
		moment,
		$filter,
		_,
		globalizationManagementService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SocietesService = SocietesService;
		this.TarifsService = TarifsService;
		this.SocietesTypesService = SocietesTypesService;
		this.MOPService = MOPService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.moment = moment;
		this.$filter = $filter;
		this.sitesCommerciaux = [];
		this.tarifs = [];
		this.selectedSens = true;
		this.transporteurs = [];
		this.loading = false;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.date = this.moment().format(this.dateFormat);

		this.previousMOP = this.MOPService.getCurrentMOP();
		this.MOPService.setMop([{ title: 'TARIFS.TITLE', filename: 'Tarifs.pdf', application: 'gestion' }]);

		const _this = this;
		this.$scope.$watch('$ctrl.date', function () {
			_this.dateChange();
		});

		this.ongletOpen = {
			isProduitOpen: false,
			isPrestationOpen: false,
			isTransportOpen: false
		};

		if (this.domaine != 'societe' && this.domaine != 'siteCommercial') {
			this.societes = await this.TarifsService.getSocietes();
			this.selectedSocieteId = this.societes[0].id;
			await this.loadSitesCommerciaux(this.selectedSocieteId);
		}

		this.getTarifs();
		this.getTransporteurs();
	}

	async $onDestroy() {
		this.MOPService.resetMop();
		if (this.previousMOP) this.MOPService.setMop(this.previousMOP, false);
	}

	async dateChange() {
		await this.getTarifs();
	}

	async getTransporteurs() {
		try {
			const data = await this.TarifsService.getTransporteurs();
			this.transporteurs = data;
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
		this.transporteurs.unshift({ id: null, libelle: '' });
	}

	async loadSitesCommerciaux() {
		this.sitesCommerciaux = await this.TarifsService.getSitesCommerciauxBySociete(this.selectedSocieteId);
	}

	async changeSens() {
		this.getTarifs();
	}

	async changeSociete() {
		await this.loadSitesCommerciaux(this.selectedSocieteId);
		this.sitesCommerciaux.unshift({ id: null, libelle: '' });
		this.getTarifs();
	}

	async changeSiteCommercial() {
		this.getTarifs();
	}

	async changeTransporteur() {
		this.getTarifs();
	}

	cancel() {
		this.modalInstance.dismiss();
	}

	async getTarifs() {
		this.loading = true;
		let urlDomainName = 'societe';
		let urlDomainId = this.selectedSocieteId;
		let filters = {};

		if (this.selectedSiteCommercialId) {
			urlDomainName = 'siteCommercial';
			urlDomainId = this.selectedSiteCommercialId;
		}

		switch (this.domaine) {
			case 'societe':
				urlDomainName = 'societe';
				urlDomainId = this.source.id;
				break;
			case 'siteCommercial':
				urlDomainName = 'siteCommercial';
				urlDomainId = this.source.id;
				break;
			case 'typeClient':
				filters = {
					typeClientId: this.source.id
				};
				break;
			case 'client':
				filters = {
					clientId: this.source.id
				};
				break;
			case 'chantier':
			case 'provenance':
				filters = {
					chantierId: this.source.id
				};
				break;
		}

		if (this.selectedTransporteur != null) {
			filters.transporteurId = this.selectedTransporteur;
		}

		filters.achatVente = this.selectedSens;
		filters.date = this.date;
		filters.idGrille = this.idGrille;

		if (urlDomainId != null) {
			try {
				const data = await this.TarifsService.getTarifs(urlDomainName, urlDomainId, filters);
				this.tarifs = data;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
		this.loading = false;
	}

	updatePrix(produit) {
		switch (produit.prixType) {
			case '1':
				produit.prix = produit.prixValeur;
				break;
			case '2':
				produit.prix = produit.prixStandard * ((100 - produit.prixValeur) / 100);
				break;
			case '3':
				produit.prix = produit.prixStandard - produit.prixValeur;
				break;
		}
	}

	async print() {
		this.openOnglets();

		await this.sleep(1000);

		const imgWidth = document.getElementById('print').clientWidth;
		const imgHeight = document.getElementById('print').clientHeight;
		const ratio = imgHeight / imgWidth;

		html2canvas(document.getElementById('print')).then(function (canvas) {
			const doc = new jsPDF('p', 'mm', 'a4');
			const pageWidth = doc.internal.pageSize.width;
			const pageHeight = doc.internal.pageSize.height;
			const imgHeight = ratio * pageWidth;
			let heightLeft = imgHeight;
			let position = 0;

			const imgMedium = canvas.toDataURL('image/jpeg', 0.5);

			doc.addImage(imgMedium, 'jpeg', 0, position, pageWidth, imgHeight);
			heightLeft -= pageHeight;

			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				doc.addPage();
				doc.addImage(imgMedium, 'jpeg', 0, position, pageWidth, imgHeight);
				heightLeft -= pageHeight;
			}
			doc.save('tarif.pdf');
		});
	}

	openOnglets() {
		this.ongletOpen = {
			isProduitOpen: true,
			isPrestationOpen: true,
			isTransportOpen: true
		};
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
}

export default class ExportFormController {
	static $inject = ['$scope', '$state', '$stateParams', '$translate', 'CentreGestionService', 'notification', 'MOPService'];

	constructor($scope, $state, $stateParams, $translate, CentreGestionService, notification, MOPService) {
		this.$scope = $scope;
		this.$state = $state;
		this.$translate = $translate;
		this.params = $stateParams;
		this.CentreGestionService = CentreGestionService;
		this.notification = notification;
		this.loading = false;
		this.outputParametres = [];
		this.MOPService = MOPService;
	}

	$onInit() {
		this.MOPService.setMop([{ title: 'CENTRE_GESTION.EXPORT_PARAMETRAGE', filename: 'ExportCentreParametrage.pdf', application: 'gestion' }]);
		this.getParametres();
	}

	async $onDestroy() {
		this.MOPService.resetMop();
	}

	async getParametres() {
		this.startLoading();
		try {
			this.inputParametres = await this.CentreGestionService.getExportParametres();
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.stopLoading();
		}
	}

	async print() {
		this.startLoading();
		const fileName = Date.now();

		const resultat = await this.CentreGestionService.printExportParametres(this.outputParametres);

		const data = resultat.data;
		let headers = resultat.headers;

		headers = headers();

		const contentType = headers['content-type'];

		const fileExtention = '.xlsx';
		const linkElement = document.createElement('a');
		try {
			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', fileName + fileExtention);

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
		} catch (ex) {
			//console.log(ex);
		} finally {
			this.stopLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}

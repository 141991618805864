// loading angular

import configurationModule from './_config';
import formComponentsModule from './form-components';
import centreGestionModule from './centre-gestion';
import caracteristiquesModule from './caracteristiques';
import directiveModule from './directives';
import filtersModule from './filters';
import domainesModule from './domaines';
import lieuxModule from './lieux';
import chantiersModule from './chantiers';
import niveauxHierarchiquesModule from './niveaux-hierarchiques';
import produitsModule from './produits';
import formulesModule from './formules';
import profilsModule from './profils';
import sitesModule from './sites';
import societeModule from './societes';
import typesModule from './types';
import unitesModule from './unites';
import ticketsModule from './tickets';
import personnesModule from './personnes';
import famillesModule from './familles';
import prospectsModule from './prospects';
import facturesModule from './factures';
import clientsDiversModule from './clients-divers';
import motifsAnnulationModule from './motifs-annulation';
import normesModule from './normes';
import tvaModule from './tva';
import donneeFiltreModule from './donnee-filtree';
import donneeFiltreUtilisateursModule from './donnee-filtree-utilisateur';
import paysModule from './pays';
import prefacturationModule from './prefacturation';
import documentModule from './documents';
import documentsCEModule from './documents-ce';
import motifsAjustementStockModule from './motifs-ajustement-stock';
import associesModule from './associes';
import historiqueModule from './historique';
import monitoringModule from './monitoring';
import parametrageGeneralModule from './parametrage-general';
import importDonneesModule from './import-donnees';
import devisesModule from './devises';
import motifsDepreciationStockModule from './motifs-depreciation-stock';
import mesureModule from './mesures';
import zoneAbattagesModule from './zone-abattages';
import zoneGisementsModule from './zone-gisements';
import tramesSaisiesModule from './trames-saisies';
import referencesControleModule from './references-controle';
import calculsStatistiquesModule from './calculs-statistiques';
import PrelevementsModule from './prelevements';
import TachesModule from './taches';
import themesModule from './themes';
//import synthesesSaisiesModule from './syntheses-saisies';
import modelesSaisiesModule from './modeles-saisies';
import syntheseModelesModule from './synthesemodeles';
import SyntheseModule from './synthese';
import imageModule from './images';
import syntheseEntitiesModule from './syntheseentities';
import seriesTamisModule from './series-tamis'; /*
import dossiersModule from './dossiers'; */
import joursFeriesModule from './jours-feries';
import labelCeModule from './label-ce';
import TemplateImpression from './template-impression';
import CodeAuto from './code-automatique';
import gammesProduits from './gammes-produits';
import releveBons from './releve-bons';
import portailModule from './portail';
import textMask from 'angular1-text-mask';
import fileUpload from 'ng-file-upload';
import configurationEmailModule from './configuration-email';
import essaisPlanifies from './essais-planifies';
import libs from '../../libs/massia-component/massia-component.module';
import compositions from './compositions';
import compteOuverts from './compte-ouverts';
// importer le module de test
import testModule from './_test_moduleMock';

//qualib
import referentiels from './qualib/referentiels';
import facteurEmissionCo2 from './facteur-emission-co2';

const moduleName = 'app.massia.common';
angular.module(moduleName, [
	libs,
	'app.core.validator',
	configurationModule,
	formComponentsModule,
	centreGestionModule,
	caracteristiquesModule,
	directiveModule,
	filtersModule,
	domainesModule,
	lieuxModule,
	chantiersModule,
	niveauxHierarchiquesModule,
	produitsModule,
	formulesModule,
	profilsModule,
	sitesModule,
	societeModule,
	typesModule,
	unitesModule,
	ticketsModule,
	personnesModule,
	famillesModule,
	prospectsModule,
	facturesModule,
	clientsDiversModule,
	motifsAnnulationModule,
	normesModule,
	tvaModule,
	textMask,
	donneeFiltreModule,
	donneeFiltreUtilisateursModule,
	paysModule,
	prefacturationModule,
	documentModule,
	fileUpload,
	documentsCEModule,
	motifsAjustementStockModule,
	associesModule,
	historiqueModule,
	monitoringModule,
	parametrageGeneralModule,
	importDonneesModule,
	devisesModule,
	motifsDepreciationStockModule,
	mesureModule,
	zoneAbattagesModule,
	zoneGisementsModule,
	tramesSaisiesModule,
	referencesControleModule,
	calculsStatistiquesModule,
	//SaisiesMesuresModule,
	PrelevementsModule,
	TachesModule,
	themesModule,
	//synthesesSaisiesModule,
	modelesSaisiesModule,
	imageModule,
	syntheseModelesModule,
	syntheseEntitiesModule,
	SyntheseModule,
	seriesTamisModule /*     dossiersModule, */,
	joursFeriesModule,
	labelCeModule,
	TemplateImpression,
	CodeAuto,
	gammesProduits,
	releveBons,
	portailModule,
	configurationEmailModule,
	essaisPlanifies,
	compositions,
	compteOuverts,
	testModule,
	referentiels,
	facteurEmissionCo2
]);

export default moduleName;
